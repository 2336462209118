import React, { memo } from 'react';
import Svg, { Path } from './svg';

const CheckChecked = ({ width, height, color }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <Path d="M0 0h24v24H0z" fill="none" />
    <Path
      fill={color}
      d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
    />
  </Svg>
);

const CheckUnchecked = ({ width, height, color }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <Path fill={color} d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    <Path d="M0 0h24v24H0z" fill="none" />
  </Svg>
);

const Check = ({ width, height, color, checked }) =>
  checked ? <CheckChecked {...{ width, height, color }} /> : <CheckUnchecked {...{ width, height, color }} />;

Check.defaultProps = {
  width: 24,
  height: 24,
  color: 'black',
  checked: false,
};

export default memo(Check);
