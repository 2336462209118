import React from 'react';
import { Text, Container, List } from '~/ui';
import { SectionListItemWithId } from '~/components/SectionListItem';

export default function LessonList({ withProgress, lesson, openSection, topicColor = '#000', ...props }) {
  return (
    <Container style={{ marginBottom: 0 }}>
      <Container
        style={{
          paddingBottom: 8,
          borderBottomWidth: 1,
          borderBottomColor: '#eee',
          borderBottomStyle: 'solid',
          flexDirection: 'column',
          alignItems: 'baseline',
          marginTop: 10,
        }}
      >
        <Text style={{ marginBottom: 0, color: topicColor }} textType="p">
          {lesson.title}
        </Text>
      </Container>
      <Container>
        <List>
          {lesson.sections.map((sectionId, key) => (
            <SectionListItemWithId
              openSection={openSection}
              {...{ key: sectionId, sectionId, ...props }}
              lastItem={lesson.sections.length <= key + 1}
              completedExercises={lesson.completedExercises}
              totalExercises={lesson.completedExercises}
              topicColor={topicColor}
            />
          ))}
        </List>
      </Container>
    </Container>
  );
}
