import { connect } from 'react-redux';
import { getActiveTopic } from '~/store/selectors/topicSelectors';

const WithTopicColor = ({ children, topic: { color, colorDark } }) => children(color, colorDark);

const mapStateToProps = (state, ownProps) => ({
  topic: getActiveTopic(state, ownProps),
});

export default connect(mapStateToProps)(WithTopicColor);
