import React from 'react';

import { sharedProps } from './index';

const FlexContainer = props => (
  <div style={{
      display: 'flex',
      justifyContent: props.justifyContent,
      alignItems: 'center',
      ...props.style,
    }}
  >
    {props.children}
  </div>
);

FlexContainer.defaultProps = {
  children: null,
  justifyContent: 'space-between',
  style: {},
};

FlexContainer.propTypes = sharedProps;

export default FlexContainer;
