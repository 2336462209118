import React, { Component } from 'react';
import { Container } from '~/ui';
import RootRef from '@material-ui/core/RootRef';

export default class AnimatedScrollView extends Component {
  scrollView = React.createRef();

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  scrollToX = x => {
    if (this.scrollView.current) {
      this.scrollView.current.scrollLeft = x;
    }
  };

  scrollToY(y) {
    if (this.scrollView.current) {
      this.scrollView.current.scrollRight = y;
    }
  }

  render() {
    const { style, ...props } = this.props;
    return (
      <RootRef rootRef={this.scrollView}>
        <Container className="scrollViewDiv" style={{ ...style, WebkitOverflowScrolling: 'touch' }} {...props}>
          {this.props.children}
          <style
            dangerouslySetInnerHTML={{
              __html: `
                .scrollViewDiv::-webkit-scrollbar {
                  display: none;
                }
              `,
            }}
          />
        </Container>
      </RootRef>
    );
  }
}
