// @flow
import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export type Props = {
  open: boolean,
  abort: () => void,
  approve: () => void,
  onClose: ?() => void,
  title: string,
  body: string,
  approveText?: string,
  abortText?: string,
  noFooter: boolean,
  webBody: any,
};

class AlertDialog extends React.PureComponent<Props> {
  static defaultProps = {
    approveText: 'Ja',
    abortText: 'Avbryt',
  };

  handleClose = () => {
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    const { abort, approve, open = false, title, body, webBody, approveText, abortText, noFooter = false } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{webBody || body}</DialogContentText>
        </DialogContent>
        {!noFooter && (
          <DialogActions>
            {abort && (
              <Button onClick={abort} color="primary">
                {abortText}
              </Button>
            )}
            <Button onClick={approve} color="secondary" autoFocus>
              {approveText}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export default AlertDialog;
