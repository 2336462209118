import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import colors from '~/ui/colors';

const themeV1 = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'Roboto'].join(','),
  },
  palette: {
    secondary: {
      main: colors.secondaryColor,
      light: colors.secondaryLightkColor,
      dark: colors.secondaryDarkColor,
      contrastText: colors.secondaryContrastColor,
    },
    primary: {
      main: colors.primaryColor,
      dark: colors.primaryDarkColor,
      light: colors.primaryLightColor,
      contrastText: colors.primaryContrastColor,
    },
    error: {
      main: '#B00020',
      dark: '#B00020',
    },
    background: {
      default: colors.lightGrey,
    },
  },
});

export default ({ children }) => (
  <MuiThemeProvider theme={themeV1}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);
