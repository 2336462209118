import React from 'react';
import Svg, { Path } from './svg';

const PauseOutlineIcon = ({ width = 24, height = 24, style = {}, color = '#000' }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" style={style}>
    <Path fill="none" d="M0 0h24v24H0V0z" />
    <Path fill={color} d="M6 5h4v14H6zm8 0h4v14h-4z" />
  </Svg>
);

export default PauseOutlineIcon;
