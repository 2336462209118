import React from 'react';

import colors from '../colors';

const UILink = props => (
  <a
    className={props.className}
    role="button"
    tabIndex="0"
    style={{
      color: colors.secondaryColor,
      cursor: 'pointer',
      display: 'block',
      outline: 'none',
      ...props.style,
    }}
    onClick={e => {
      e.stopPropagation();
      return props.url ? window.open(props.url, props.url.includes('mailto') ? '_self' : '_blank') : props.action();
    }}
    onKeyPress={e => {
      e.stopPropagation();
      return props.url ? window.open(props.url, props.url.includes('mailto') ? '_self' : '_blank') : props.action();
    }}
  >
    {props.children}
  </a>
);

UILink.defaultProps = {
  children: null,
  style: null,
  action: () => {},
};

export default UILink;
