import { connect } from 'react-redux';
import SectionListItem from './SectionListItem';
import { makeGetSectionById } from '~/store/selectors/sectionSelectors';
import { getActiveTopic } from '~/store/selectors/topicSelectors';

const makeMapStateToProps = () => {
  const getSectionById = makeGetSectionById();
  return (state, ownProps) => ({
    section: getSectionById(state, ownProps),
    topic: getActiveTopic(state, ownProps),
  });
};

export default connect(makeMapStateToProps)(SectionListItem);
