import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ProfileButton from '~/components/ProfileButton';
import SearchButton from '~/components/SearchButton';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import BackButton from '~/components/BackButton';
import {Colors, FadeInContainer} from '~/ui';

const drawerWidth = 300;
const boxShadowColor = '#888888';

const getAppHeight = width => {
  if (width === 'xs') return 52;
  return 64;
};

const Layout = ({
  hasAppBar,
  hasBackButton,
  hasProfileButton,
  hasSearchButton = false,
  aside,
  style,
  title,
  children,
  width,
}) => {
  const appBarHeight = hasAppBar ? getAppHeight(width) : 0;

  return (
    <div style={style}>
      {hasAppBar ? (
        <div>
          <AppBar
            style={{
              backgroundColor: Colors.headerColor,
            }}
            position="fixed">
            <Toolbar style={{height: appBarHeight, minHeight: appBarHeight}}>
              {hasBackButton ? (
                <BackButton
                  color="white"
                  style={{marginLeft: -12, marginRight: 20, marginTop: 4}}
                />
              ) : null}
              <div style={{alignSelf: 'center', flexGrow: 1}}>{title}</div>
              {hasSearchButton && (
                <SearchButton
                  style={{marginLeft: 8}}
                  variant={isWidthUp('sm', width) ? 'text' : 'icon'}
                  color="white"
                />
              )}
              {/* {hasProfileButton ? (
                <ProfileButton
                  style={{marginLeft: 8}}
                  variant={isWidthUp('sm', width) ? 'text' : 'icon'}
                  color="white"
                />
              ) : null} */}
            </Toolbar>
          </AppBar>
        </div>
      ) : null}
      <FadeInContainer
        show
        style={{
          marginTop: appBarHeight,
          display: 'flex',
          flexDirection: 'row',
        }}>
        {aside && isWidthUp('md', width) ? (
          <div
            style={{
              overflowX: 'hidden',
              height: `calc(100vh - ${appBarHeight}px)`,
              width: drawerWidth,
              position: 'relative',
              zIndex: 99,
            }}>
            <div
              style={{
                overflowY: 'auto',
                boxShadow: `1px 0px 5px ${boxShadowColor}`,
                width: drawerWidth,
                position: 'fixed',
              }}>
              {aside}
            </div>
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            flex: 1,
            minHeight: `calc(100vh - ${appBarHeight}px`,
            overflowY: 'auto',
          }}>
          {children}
        </div>
      </FadeInContainer>
    </div>
  );
};

Layout.defaultProps = {
  title: 'My Driving Academy Online',
};

export default withWidth()(Layout);
