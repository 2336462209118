import React from 'react';
import {FadeInContainer, Card, Text, Button, Container, Colors} from '~/ui';

const WelcomeInfo = ({toggleModal, navigate}) => (
  <FadeInContainer show style={{paddingLeft: 14, paddingRight: 14}}>
    <Card
      style={{
        maxWidth: 800,
        borderRadius: 20,
        padding: 24,
        backgroundColor: Colors.white,
        margin: '0px auto',
      }}>
      <Text
        textType="h3"
        style={{color: Colors.secondaryColor, paddingBottom: 14}}>
        Så här kommer du igång med My Driving Academy Online!
      </Text>
      <Text style={{marginTop: 40, lineHeight: 1.6}}>
        För att komma igång med My Driving Academy Online så behöver du teckna
        en prenumeration. Detta gör du enklast genom att klicka på knappen
        &quot;Till beställning&quot; och slutföra köpet hos vår betalleverantör
        Klarna. Om du har en kupongkod så ska du istället klicka på knappen
        &quot;Lös in kupongkod&quot;.
      </Text>
      <Container
        style={{display: 'flex', flexDirection: 'row', marginTop: 5}}
        smallStyle={{flexWrap: 'wrap'}}>
        <Button
          action={() => navigate('checkout')}
          style={{marginRight: 20, marginTop: 15}}
          text="TILL BESTÄLLNING"
        />
        <Button
          action={toggleModal}
          style={{marginTop: 15}}
          text="LÖS IN KUPONGKOD"
        />
      </Container>
    </Card>
  </FadeInContainer>
);

export default WelcomeInfo;
