import React, { Component } from 'react';
import { connect } from 'react-redux';

import { authSignInWithEmailAction } from '~/store/actions/authActions';
import { Text, Button, Input, Colors, Container, Loader } from '~/ui';

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
    errorMessage: '',
    error: false,
    loading: false,
  };

  handleInputUpdate = name => value => {
    this.setState({ [name]: value });
  };

  login() {
    const { email, password } = this.state;
    if (email && password) {
      this.setState({ loading: true });
      this.props.dispatch(authSignInWithEmailAction(email, password)).then(auth => {
        if (!auth.status) {
          if (auth.response.code === 'auth/invalid-email') {
            this.setState({ error: true, errorMessage: 'E-postadressen är inte korrekt formaterad', loading: false });
          } else if (auth.response.code === 'auth/user-not-found') {
            this.setState({
              error: true,
              errorMessage: 'Kan tyvärr inte hitta någon användare med denna e-postadress',
              loading: false,
            });
          } else if (auth.response.code === 'auth/wrong-password') {
            this.setState({
              error: true,
              errorMessage: 'Lösenordet är ogiltigt eller användaren har inget lösenord',
              loading: false,
            });
          }
        }
      });
    } else {
      this.setState({ error: true, errorMessage: 'Fyll i din e-postadress och ditt lösenord' });
    }
  }

  render() {
    const { email, password, errorMessage, error, loading } = this.state;

    return (
      <Container style={styles.cardContent} mediumStyle={{ maxWidth: 350 }}>
        <Container style={styles.textField}>
          <Input
            label="E-post"
            value={email}
            type="email"
            onChange={this.handleInputUpdate('email')}
            id="email"
            nativeProps={{
              returnKeyType: 'next',
              textContentType: 'username',
              onSubmitEditing: () => {
                this.secondTextInput.focus();
              },
            }}
          />
        </Container>
        <Container style={styles.textField}>
          <Input
            label="Lösenord"
            value={password}
            onChange={this.handleInputUpdate('password')}
            type="password"
            id="password"
            nativeProps={{
              returnKeyType: 'go',
              textContentType: 'password',
              ref: input => {
                this.secondTextInput = input;
              },
              onSubmitEditing: () => {
                this.login();
              },
            }}
          />
        </Container>
        {error && <Text style={{ color: Colors.dangerColor }}>{errorMessage}</Text>}

        {loading ? (
          <Container
            style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 16, marginTop: 16 }}
          >
            <Loader />
          </Container>
        ) : (
          <Button
            action={() => {
              this.login();
            }}
            fullWidth
            text="Logga in"
            style={styles.loginButton}
          />
        )}
      </Container>
    );
  }
}

const styles = {
  card: {
    maxWidth: 550,
    width: '100%',
    margin: 'auto',
    paddingTop: 40,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: Colors.surfaceDark,
    borderRadius: 20,
  },
  cardContent: {
    width: '100%',
    margin: 'auto',
    position: 'relative',
  },
  textField: {
    marginBottom: 20,
  },
  loginButton: {
    height: 45,
    marginBottom: 10,
    marginTop: 15,
  },
};

export default connect(state => ({ auth: state.auth }))(LoginForm);
