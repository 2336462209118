import { createSelector } from 'reselect';

const getActiveLessonId = state => state.ui.activeLesson;
const getLessons = state => state.entities.lessons;
const getLesson = (state, props) => ({ ...state.entities.lessons[props.lessonId], id: props.lessonId });
const getSections = state => state.entities.sections;
const getCompletedExercises = state => Object.keys(state.auth.user.progress || {});

const getProgressForLesson = (lesson, sections, completed) => {
  const sectionsInLesson = lesson.sections.map(sectionId => sections[sectionId]).filter(section => section);
  const allExerciseIds = [].concat(...sectionsInLesson.map(section => section.exercises));
  const completedExerciseIds = allExerciseIds.filter(exerciseId => completed.includes(exerciseId));
  return {
    ...lesson,
    totalExercises: allExerciseIds.length,
    completedExercises: completedExerciseIds.length,
  };
};

export const getAllLessons = createSelector(
  [getLessons, getSections, getCompletedExercises],
  (lessons, sections, completed) =>
    Object.entries(lessons).map(entry => getProgressForLesson({ ...entry[1], id: entry[0] }, sections, completed))
);

export const makeGetLessonById = () =>
  createSelector([getLesson, getSections, getCompletedExercises], getProgressForLesson);

export const getActiveLesson = createSelector([getActiveLessonId, getAllLessons], (lessonId, lessons) =>
  lessons.find(lesson => lesson.id === lessonId)
);
