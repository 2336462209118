import React, { Component } from 'react';
import classNames from 'classnames';
import css from './feedbackMessage.css';
import { Text } from '~/ui';
import colors from '~/ui/colors';
import WithTopicColor from '~/components/WithTopicColor';

export default class FeedbackMessage extends Component {
  queue = [];

  timeout = null;

  state = {
    open: false,
    messageInfo: {},
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.handleClick(this.props.message);
      this.props.onClose();
    }
    if (prevProps.slideDirection !== this.props.slideDirection && this.props.slideDirection) {
      this.setTransition();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleClick = queuedMessage => {
    this.queue.push({
      queuedMessage,
      key: new Date().getTime(),
    });

    if (this.state.open) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ open: false });
      clearTimeout(this.timeout);
      setTimeout(() => this.processQueue(), 100);
    } else {
      this.processQueue();
    }
  };

  processQueue = () => {
    if (this.queue.length > 0) {
      this.timeout = setTimeout(() => this.setState({ open: false }), 1500);
      this.setState({
        messageInfo: this.queue.shift(),
        open: true,
      });
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  handleExited = () => {
    this.processQueue();
    this.props.onClose();
  };

  render() {
    const { variant } = this.props;
    const { open } = this.state;
    const { queuedMessage } = this.state.messageInfo;

    const classes = ['feedbackMessage'];
    if (open) classes.push('feedbackMessage--open');

    return (
      <WithTopicColor>
        {topicColor => {
          const backgroundColor = variant === 'primary' ? topicColor : colors[`${variant}Color`];
          return (
            <div style={{ backgroundColor }} className={classNames(classes)}>
              <Text textType="h3" style={{ color: '#fff', fontWeight: 'bold' }}>
                {queuedMessage}
              </Text>
            </div>
          );
        }}
      </WithTopicColor>
    );
  }
}
