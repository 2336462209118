// @flow
import React, { Component } from 'react';
import { Container, Link } from '~/ui';
import { VolumeUpOutlineIcon, PauseOutlineIcon } from '~/icons';
import CircleAnimation from '~/components/CircleAnimation';

type Props = {
  paused: boolean,
  duration: number,
  ended: boolean,
  togglePlaySound: () => void,
  toggleMuteSound: () => void,
};

class PlayerControls extends Component<Props> {
  togglePlaySound() {
    this.props.togglePlaySound();
  }

  toggleMuteSound() {
    this.props.toggleMuteSound();
  }

  render() {
    const { paused, duration, ended } = this.props;

    return (
      <Container
        style={{
          width: 48,
          height: 48,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Container style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}>
          <CircleAnimation width={48} height={48} play={!paused} duration={duration} ended={ended} />
        </Container>
        <Link
          action={() => this.togglePlaySound()}
          style={{ display: 'flex', zIndex: 1, padding: 14, borderRadius: 99 }}
        >
          {paused && <VolumeUpOutlineIcon color="#fff" />}
          {!paused && <PauseOutlineIcon color="#fff" />}
        </Link>
      </Container>
    );
  }
}

export default PlayerControls;
