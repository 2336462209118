import React, { Component } from 'react';
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import green from '@material-ui/core/colors/green';
import withStyles from '@material-ui/core/styles/withStyles';
import { SnackbarContent, Slide } from '@material-ui/core';

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  danger: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const SlideIn = (direction = 'left') => props => <Slide {...props} direction={direction} />;

class WebSnackbar extends Component {
  queue = [];

  state = {
    open: false,
    messageInfo: {},
    transition: SlideIn(this.props.slideDirection ? this.props.slideDirection : 'down'),
  };

  componentDidMount() {
    if (this.props.open) {
      this.handleClick(this.props.message);
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.handleClick(this.props.message);
      this.props.onClose();
    }
    if (prevProps.slideDirection !== this.props.slideDirection && this.props.slideDirection) {
      this.setTransition();
    }
  }

  setTransition() {
    this.setState({ transition: SlideIn(this.props.slideDirection) });
  }

  handleClick = queuedMessage => {
    this.setState({ open: true });
    this.queue.push({
      queuedMessage,
      key: new Date().getTime(),
    });

    if (this.state.open) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ open: false });
    } else {
      this.processQueue();
    }
    if (this.state.open) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ open: false });
    } else {
      this.processQueue();
    }
  };

  processQueue = () => {
    if (this.queue.length > 0) {
      this.setState({
        messageInfo: this.queue.shift(),
        open: true,
      });
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  handleExited = () => {
    this.processQueue();
    this.props.onClose();
  };

  render() {
    const { webPosition, className, variant, classes } = this.props;
    const { open, transition } = this.state;
    const { queuedMessage, key } = this.state.messageInfo;

    return (
      <Snackbar
        TransitionComponent={transition}
        autoHideDuration={10000}
        disableWindowBlurListener
        onExited={this.handleExited}
        anchorOrigin={webPosition}
        key={key}
        open={open}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          message={
            <span id="client-snackbar" className={{ ...classes.message }}>
              {queuedMessage}
            </span>
          }
          className={classNames(classes[variant], className)}
        />
      </Snackbar>
    );
  }
}

export default withStyles(styles)(WebSnackbar);
