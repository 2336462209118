// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from '~/ui';
import { BackIcon, CloseIcon } from '~/icons';
import { navToAction } from '~/store/actions/navActions';

type Target = 'home' | 'topic' | 'section';

type Props = {
  style: {},
  goBack: () => void,
  close: boolean,
  color: string,
  // eslint-disable-next-line react/no-unused-prop-types
  target: ?Target,
};

const BackButton = ({ style, goBack, close, color }: Props) => (
  <Link style={style} action={goBack}>
    {close ? <CloseIcon color={color || 'white'} /> : <BackIcon color={color || 'white'} />}
  </Link>
);

const goToTarget = (target: Target) => () => (dispatch, getState) => {
  const {
    ui: { activeTopic, activeSection },
  } = getState();
  switch (target) {
    case 'home':
      dispatch(navToAction('home'));
      break;
    case 'topic':
      dispatch(navToAction('topic', { topicId: activeTopic }));
      break;
    default:
      dispatch(navToAction('section', { sectionId: activeSection, topicId: activeTopic }));
      break;
  }
};

const goToUp = () => (dispatch, getState) => {
  const {
    ui: { activeTopic, activeSection, activeExercise },
  } = getState();
  if (activeExercise) {
    dispatch(navToAction('section', { topicId: activeTopic, sectionId: activeSection }));
  } else if (activeSection) {
    dispatch(navToAction('topic', { topicId: activeTopic }));
  } else {
    dispatch(navToAction('home'));
  }
};

const goBack = (target: ?Target) => {
  if (target) {
    return goToTarget(target);
  }

  return goToUp;
};

const mapDispatchToProps = (dispatch, ownProps: Props) =>
  bindActionCreators(
    {
      goBack: goBack(ownProps.target),
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(BackButton);
