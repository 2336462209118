import React, { Component } from 'react';
import { Container } from '~/ui';

export class FadeInContainer extends Component {
  state = {
    opacity: 0,
    translateY: this.props.slideIn ? 20 : 'none',
  };

  componentDidMount() {
    if (this.props.show) {
      setTimeout(() => {
        this.fadeIn();
      }, 5);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      this.fadeIn();
    } else if (prevProps.show !== this.props.show && !this.props.show) {
      this.fadeOut();
    }
  }

  fadeIn() {
    this.setState({ opacity: 1, translateY: 0 });
  }

  fadeOut() {
    this.setState({ opacity: 0, translateY: this.props.slideIn ? 20 : 'none' });
  }

  render() {
    const { opacity, translateY } = this.state;
    const { style: propsStyle, children, ...props } = this.props;

    return (
      <Container
        style={{
          ...style,
          ...propsStyle,
          opacity,
          transform: this.props.slideIn ? `translateY(${translateY}px)` : 'none',
        }}
        {...props}
      >
        {children}
      </Container>
    );
  }
}

const style = {
  transition: 'opacity 0.3s ease, transform 0.3s ease',
};

export default FadeInContainer;
