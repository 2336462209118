import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navToAction } from '~/store/actions/navActions';
import { Colors, Container, Text, Loader, ScrollView } from '~/ui';
import TopicPanel from '~/components/topicPanel';
import VoucherModal from '~/components/VoucherModal';
import WelcomeInfo from '~/components/WelcomeInfo';
import { setActiveTopicAction, clearAction } from '~/store/actions/uiActions';
import { getFirebaseSvgOrPngUrl } from '~/utils/helpers';
import { bindActionCreators } from 'redux';
import { getAllTopicsWithProgress } from '~/store/selectors/topicSelectors';

class TopicOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  render() {
    const { showModal } = this.state;
    const { topics, navigate, openTopic, approvedKnowledgeTests, user } = this.props;
    const { validSubscription, validSubscriptionChecked } = user;

    if (topics !== null) {
      return topics.length > 0 ? (
        <ScrollView
          key="Utbildning"
          style={{
            overFlow: 'hidden',
            width: '100%',
            backgroundColor: Colors.lightGrey,
          }}
          webstyle={{ flexDirection: 'column', alignItems: 'center', paddingTop: 40 }}
          smallStyle={{ paddingTop: 5 }}
        >
          {typeof document !== 'undefined' && validSubscriptionChecked && !validSubscription && (
            <WelcomeInfo toggleModal={this.toggleModal} navigate={() => navigate('checkout')} />
          )}
          <Container wrapWebContent
            style={{
              maxWidth: 1070,
              marginBottom: 30,
              paddingLeft: 10,
              paddingRight: 10,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              flex: 1,
              width: '100%',
              fontSize: 'larger',
              fontWeight: 700,
              color: 'red'
            }}
            nativeStyle={{ paddingTop: 20 }}>
          {'Vi förbättrar vårt innehåll i plugga-delen. Vänligen ha tålamod med eventuella följdeffekter under de kommande veckorna.'}
          </Container>
          <Container
            wrapWebContent
            style={{
              maxWidth: 1070,
              marginBottom: 30,
              paddingLeft: 10,
              paddingRight: 10,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              flex: 1,
              width: '100%',
            }}
            nativeStyle={{ paddingTop: 20 }}
          >
            {topics
            .filter(topic => topic.title !== 'Kunskapsprovet') // Jessica asked to remove this topic
            .map(topic => (
              <Container
                style={{ display: 'flex' }}
                smallStyle={{ width: '100%', paddingBottom: 16 }}
                mediumStyle={{ width: '50%', padding: 8 }}
                mediumNativeStyle={{ width: '50%', padding: 8 }}
                largeStyle={{ width: '33.33%' }}
                key={topic.id}
              >
                {topic.signatureImage && (
                  <TopicPanel
                    image={getFirebaseSvgOrPngUrl(topic.signatureImage)}
                    topic={topic}
                    openTopic={() => openTopic(topic)}
                    approvedKnowledgeTests={approvedKnowledgeTests}
                  />
                )}
              </Container>
            ))}
          </Container>
          {typeof document !== 'undefined' && (
            <VoucherModal visible={showModal} onClose={() => this.setState({ showModal: false })} />
          )}
        </ScrollView>
      ) : (
        <Container
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.lightGrey,
          }}
        >
          <Loader />
        </Container>
      );
    }
    return <Text>Fetching topics failed</Text>;
  }
}

const navigate = route => dispatch => {
  dispatch(navToAction(route));
};

const openTopic = topic => dispatch => {
  dispatch(setActiveTopicAction(topic.title));
  dispatch(navToAction('topic', { topicId: topic.title }));
};

const mapStateToProps = state => ({
  topics: getAllTopicsWithProgress(state),
  user: state.auth.user,
  approvedKnowledgeTests:
    state.auth.user && state.auth.user.approvedKnowledgeTests ? state.auth.user.approvedKnowledgeTests : 0,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clear: clearAction,
      openTopic,
      navigate,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicOverview);
