import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { Icon, FlexContainer, Text, Container } from '~/ui';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import { sharedProps } from './index';

const UIList = props => (
  <List component="nav" subheader={props.title}>
    {props.children}
  </List>
);

UIList.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

UIList.defaultProps = {
  children: null,
  title: null,
};

const UIListItem = props => {
  if (props.loader) {
    return (
      <FlexContainer justifyContent="flex-start" style={{ paddingLeft: 4, height: 48, width: 280 }}>
        <Container style={{ padding: 10, marginRight: 14 }}>{props.leftIcon}</Container>
        <Text>{props.primaryText}</Text>
      </FlexContainer>
    );
  }
  if (props.subItems) {
    return <ExpandableListItem {...props} />;
  }
  return (
    <ListItem button onClick={props.action != null ? () => props.action() : null}>
      {props.leftIcon ? <ListItemIcon>{props.leftIcon}</ListItemIcon> : null}
      <ListItemText primary={props.primaryText} secondary={props.secondaryText} />
      {props.rightIcon ? <ListItemSecondaryAction>{props.rightIcon}</ListItemSecondaryAction> : null}
    </ListItem>
  );
};

class ExpandableListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    return (
      <React.Fragment>
        <ListItem button onClick={() => this.setState(prevState => ({ isOpen: !prevState.isOpen }))}>
          {this.props.leftIcon ? <ListItemIcon>{this.props.leftIcon}</ListItemIcon> : null}
          <ListItemText primary={this.props.primaryText} secondary={this.props.secondaryText} />
          {this.state.isOpen ? (
            <ListItemSecondaryAction>
              <Icon color="black" iconName="md-arrow-dropup" />
            </ListItemSecondaryAction>
          ) : (
            <ListItemSecondaryAction>
              <Icon color="black" iconName="md-arrow-dropdown" />
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <Collapse in={this.state.isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {this.props.subItems}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }
}

UIListItem.propTypes = sharedProps;

export { UIList, UIListItem };
