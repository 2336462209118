import { createBrowserHistory } from 'history';
import { getRoute } from './routeList';

export const history = createBrowserHistory();

export const navToFunction = (screenKey, params, replace = false) => dispatch => {
  const webParams = { ...params };
  if (webParams.nativeParams) delete webParams.nativeParams;
  const route = getRoute(screenKey);
  let url = route.webUrl;

  if (webParams && webParams !== {}) {
    Object.keys(webParams).map(k => {
      url = url.replace(`:${k}`, webParams[k]);
      return url;
    });
  }

  dispatch({ type: 'NAVIGATE' });
  if (replace) {
    history.replace(url);
  } else {
    history.push(url);
  }
};
