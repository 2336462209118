import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import instructionsMiddleware from '~/store/middlewares/instructionsMiddleware';
import authReducer from '~/store/reducers/authReducer';
import entitiesReducer from './reducers/entitiesReducer';
import uiReducer from './reducers/uiReducer';
import instructionsReducer from './reducers/instructionReducer';
import Storage from '~/utils/storage';

const persistConfig = {
  key: 'root',
  storage: Storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['entities'],
};

const entitiesPersistConfig = {
  key: 'entities',
  storage: Storage,
  blacklist: ['fetchedSubscriptionContent'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  entities: persistReducer(entitiesPersistConfig, entitiesReducer),
  ui: uiReducer,
  instructions: instructionsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk, instructionsMiddleware];

// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

export const store = createStore(persistedReducer, enhancer);

export const persistor = persistStore(store);
