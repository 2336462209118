import PropTypes from 'prop-types';

export { default } from './UIImage';

export const sharedProps = {
  src: PropTypes.string,
  srcImage: PropTypes.node,
  style: PropTypes.shape({}),
  alt: PropTypes.string,
};
