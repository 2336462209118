import React from 'react';

import { Colors, Text, Container } from '~/ui';

export default () => (
  <Container wrapWebContent style={{ backgroundColor: Colors.primaryColor }}>
    <Text textType="h1" style={{ color: Colors.white }}>
      Oh no, you&apos;ve found our junior developer&apos;s homepage!
    </Text>
  </Container>
);
