import { navToAction } from '~/store/actions/navActions';

export const clearAction = () => ({
  type: 'CLEAR_UI',
});

export const setActiveTopicAction = topicId => (dispatch, getState) => {
  const { topics } = getState().entities;
  if (topicId && Object.keys(topics).filter(topicKey => topics[topicKey].title === topicId).length === 0) {
    dispatch(navToAction('pageNotFound', {}, true));
  } else {
    dispatch({
      type: 'SET_ACTIVE_TOPIC',
      topicId,
    });
  }
};

export const setActiveLessonAction = lessonId => (dispatch, getState) => {
  const activeTopicId = Object.entries(getState().entities.topics).find(
    x => x[1].lessons && x[1].lessons.includes(lessonId)
  );

  if (!activeTopicId) {
    dispatch(navToAction('pageNotFound', {}, true));
  } else {
    dispatch(setActiveTopicAction(getState().entities.topics[activeTopicId[0]].title));
    dispatch({
      type: 'SET_ACTIVE_LESSON',
      lessonId,
    });
  }
};

export const setActiveSectionAction = sectionId => (dispatch, getState) => {
  const activeLessonId = Object.entries(getState().entities.lessons).find(x => x[1].sections.includes(sectionId));

  if (!activeLessonId) {
    dispatch(navToAction('pageNotFound', {}, true));
  } else {
    dispatch(setActiveLessonAction(activeLessonId[0]));
    dispatch({
      type: 'SET_ACTIVE_SECTION',
      sectionId,
    });
  }
};

export const setActiveExerciseAction = exerciseId => (dispatch, getState) => {
  const activeSectionId = Object.entries(getState().entities.sections).find(x => x[1].exercises.includes(exerciseId));

  if (!activeSectionId) {
    dispatch(navToAction('pageNotFound', {}, true));
  } else {
    dispatch(setActiveSectionAction(activeSectionId[0]));
    dispatch({
      type: 'SET_ACTIVE_EXERCISE',
      exerciseId,
    });
  }
};

export const setStatusBarVisibilityAction = visible => ({
  type: 'SET_STATUS_BAR_VISIBILITY',
  visible,
});
