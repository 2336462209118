import { createSelector } from 'reselect';
import { getTopicFromTitleOrId } from '~/utils/helpers';

const getSections = state => state.entities.sections;
const getExercises = state => state.entities.exercises;
const getSection = (state, props) => ({ ...state.entities.sections[props.sectionId], id: props.sectionId });
const getActiveSectionId = state => state.ui.activeSection;
const getTopics = state => state.entities.topics;
const getLessons = state => state.entities.lessons;
const getLessonsForActiveTopic = state => {
  const activeTopic = state.entities.topics[state.ui.activeTopic]
    ? state.entities.topics[state.ui.activeTopic]
    : getTopicFromTitleOrId(state.entities.topics, state.ui.activeTopic);
  return activeTopic.lessons.map(lessonId => ({ ...state.entities.lessons[lessonId], id: lessonId }));
};
const getUserProgress = state => (state.auth.user ? state.auth.user.progress : {});

export const getAllSections = createSelector([getSections], sections =>
  Object.entries(sections).map(entry => ({ ...entry[1], id: entry[0] }))
);

export const getSectionsForActiveTopic = createSelector(
  [getLessonsForActiveTopic, getAllSections],
  (lessons, sections) => {
    const sectionIds = [].concat(...lessons.map(lesson => lesson.sections));
    return sections.filter(section => sectionIds.includes(section.id));
  }
);

export const getActiveSection = createSelector([getActiveSectionId, getSections], (activeSectionId, sections) => ({
  ...sections[activeSectionId],
  id: activeSectionId,
}));

export const makeGetSectionById = () =>
  createSelector([getSection, getUserProgress, getExercises], (section, userProgress, exercises) => {
    const completedExercises = userProgress ? section.exercises.filter(exerciseId => userProgress[exerciseId]) : [];
    const nrExerciseTasks = section.exercises.length;
    // .filter(
    //   exerciseId => !exercises[exerciseId].template.includes('content::') // || exercises[exerciseId].template === 'content::FmsHTML'
    // ).length;
    const completedControlQuesstionExercise =
      completedExercises.filter(
        exerciseId => exercises[exerciseId] && exercises[exerciseId].template === 'question::OneOfFour'
      ).length > 0;
    return { ...section, completedExercises, completedControlQuesstionExercise, nrExerciseTasks };
  });

export const getSectionsWithTopic = createSelector(
  [getSections, getTopics, getLessons, getUserProgress, getExercises],
  (sections, topics, lessons, userProgress, exercises) =>
    Object.keys(sections).map(sectionKey => {
      const completedExercises = userProgress
        ? sections[sectionKey].exercises.filter(exerciseId => userProgress[exerciseId])
        : [];
      const nrExerciseTasks = sections[sectionKey].exercises.filter(
        exerciseId => !exercises[exerciseId].template.includes('content::')
      ).length;
      const completedControlQuesstionExercise =
        completedExercises.filter(
          exerciseId => exercises[exerciseId] && exercises[exerciseId].template === 'question::OneOfFour'
        ).length > 0;
      const lesson = Object.keys(lessons).find(lessonKey => lessons[lessonKey].sections.includes(sectionKey));
      const topic = Object.keys(topics).find(
        topicKey => topics[topicKey].lessons && topics[topicKey].lessons.includes(lesson)
      );
      return {
        ...sections[sectionKey],
        topic,
        lesson,
        completedExercises,
        completedControlQuesstionExercise,
        nrExerciseTasks,
        id: sectionKey,
      };
    })
);
