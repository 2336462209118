import React from 'react';
import PropTypes from 'prop-types';

const UILeft = props => (
  <div style={{
    textAlign: 'left',
  }}
  >
    {props.children}
  </div>
);

UILeft.propTypes = {
  children: PropTypes.node,
};
UILeft.defaultProps = {
  children: null,
};

const UIRight = props => (
  <div style={{
    textAlign: 'right',
  }}
  >
    {props.children}
  </div>
);

UIRight.propTypes = {
  children: PropTypes.node,
};
UIRight.defaultProps = {
  children: null,
};


export { UILeft, UIRight };
