import React, { Component } from 'react';
import Svg, { G, Path, Polygon, Rect } from './svg';

export default class TwoToneThumbsUpIcon extends Component {
  static defaultProps = { width: 24, height: 24 };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.color !== nextProps.color ||
      this.props.width !== nextProps.width ||
      this.props.height !== nextProps.height
    );
  }

  render() {
    return (
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/Svg"
        x="0"
        y="0"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
        enableBackground="new 0 0 24 24"
      >
        <G id="Bounding_Boxes">
          <G id="ui_x5F_spec_x5F_header_copy_3" />
          <Path fill="none" d="M0,0h24v24H0V0z" />
          <Path fill="none" d="M0,0h24v24H0V0z" />
        </G>
        <G id="Duotone">
          <G id="ui_x5F_spec_x5F_header_copy_2" />
          <G>
            <Polygon fill={this.props.color} opacity="0.3" points="21,12 21,10 12,10 13.34,4.66 9,9 9,19 18,19 		" />
            <Path
              fill={this.props.color}
              d="M9,21h9c0.83,0,1.54-0.5,1.84-1.22l3.02-7.05C22.95,12.5,23,12.26,23,12v-2c0-1.1-0.9-2-2-2h-6.31l0.95-4.57l0.03-0.32
			c0-0.41-0.17-0.79-0.44-1.06L14.17,1L7.58,7.59C7.22,7.95,7,8.45,7,9v10C7,20.1,7.9,21,9,21z M9,9l4.34-4.34L12,10h9l0,2l-3,7H9V9
			z"
            />
            <Rect fill={this.props.color} x="1" y="9" width="4" height="12" />
          </G>
        </G>
      </Svg>
    );
  }
}
