import React from 'react';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

const containerStyle = {
  maxWidth: '720px',
  marginTop: '20px',
  marginBottom: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'Regular',
  boxSizing: 'border-box',
};

const UIContainer = ({
  webFixed,
  wrapWebContent,
  style,
  children,
  webstyle,
  smallStyle,
  mediumStyle,
  largeStyle,
  width,
  onWheel,
  className,
}) => (
  <div
    onWheel={onWheel || null}
    style={{
      ...(wrapWebContent ? containerStyle : {}),
      // This is due to overflow in flex behavior in firefox see https://stackoverflow.com/questions/26895349/how-can-i-get-ff-33-x-flexbox-behavior-in-ff-34-x
      ...{ minWidth: 0, minHeight: 0 },
      ...style,
      ...(webstyle || {}),
      ...(webFixed ? { position: 'fixed' } : {}),
      ...(isWidthDown('xs', width) && smallStyle ? smallStyle : {}),
      ...(isWidthUp('sm', width) && mediumStyle ? mediumStyle : {}),
      ...(isWidthUp('md', width) && largeStyle ? largeStyle : {}),
    }}
    className={className}
  >
    {children}
  </div>
);

export default withWidth()(UIContainer);
