import React, { PureComponent } from 'react';
import { Container, Link, Text, Colors } from '~/ui';
import { Check } from '~/icons';

export default class UICheckbox extends PureComponent {
  static defaultProps = {
    color: 'primary',
    checked: false,
  };

  state = {
    checked: false,
  };

  get color() {
    switch (this.props.color) {
      case 'primary':
        return Colors.primaryColor;
      default:
        return Colors.secondaryColor;
    }
  }

  toggleChecked = () => {
    this.setState(
      prevState => ({
        checked: !prevState.checked,
      }),
      () => {
        this.props.onChange(this.state.checked);
      }
    );
  };

  render() {
    const { label } = this.props;
    const { checked } = this.state;
    return (
      <Link action={this.toggleChecked}>
        <Container style={styles.root}>
          <Check color={this.color} checked={checked} />
          {typeof label === 'string' ? (
            <Text style={styles.text} textType="p">
              {label}
            </Text>
          ) : (
            label
          )}
        </Container>
      </Link>
    );
  }
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  text: {
    marginTop: 2,
    marginLeft: 8,
  },
};
