import PropTypes from 'prop-types';

import { UIList, UIListItem } from './List';


export {
  UIList as List,
  UIListItem as ListItem,
};

export const sharedProps = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  subItems: PropTypes.arrayOf(PropTypes.node),
  action: PropTypes.func,
  style: PropTypes.shape({}),
  loader: PropTypes.bool,
};

export const defaultSharedProps = {
  secondaryText: null,
  leftIcon: null,
  rightIcon: null,
  subItems: null,
  action: () => {},
};
