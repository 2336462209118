import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { FlexContainer } from '~/ui';
import { Typography } from '@material-ui/core';

const UICard = props => (
  <Card
    style={{
      margin: 5,
      minWidth: 300,
      flex: 1,
      borderRadius: 20,
      paddingBottom: 0,
      ...props.style,
    }}
    elevation={props.elevation}
  >
    {props.children}
  </Card>
);

UICard.defaultProps = {
  children: null,
  style: {},
};

const UICardHeader = props => <CardHeader>{props.children}</CardHeader>;

UICardHeader.defaultProps = {
  title: '',
  subtitle: '',
  icon: '',
};

const UICardTitle = props => (
  <div>
    <Typography gutterBottom component="h2" variant="headline">
      {props.title}
    </Typography>
    {props.subtitle ? (
      <Typography subtitle={props.subtitle} style={{ paddingBottom: 0, ...props.style }}>
        {props.subtitle}
      </Typography>
    ) : null}
  </div>
);

UICardTitle.defaultProps = {
  title: '',
  subtitle: '',
  style: null,
};

const UICardBody = props => (
  <CardContent style={{ paddingBottom: 16, ...props.style, ...props.webstyle }}>{props.children}</CardContent>
);

UICardBody.defaultProps = {
  children: null,
  style: {},
  webstyle: {},
};

const UICardImage = props => (
  <CardMedia style={{ height: 200, overflow: 'hidden', ...props.style }} image={props.src} title={props.alt} />
);

const UICardVideo = props => <CardMedia component="video" src={props.src} title={props.alt} />;

UICardImage.defaultProps = {
  src: '',
  alt: 'Topic image',
};

const UICardFooter = props => (
  <div
    style={{
      padding: 16,
      bottom: 0,
      marginTop: 'auto',
    }}
  >
    <FlexContainer justifyContent={props.justifyContent}>{props.children}</FlexContainer>
  </div>
);

UICardFooter.defaultProps = {
  justifyContent: 'space-between',
  children: null,
};

const UICustomCardHeader = props => (
  <div
    style={{
      padding: 16,
      paddingBottom: 0,
    }}
  >
    <FlexContainer
      justifyContent={props.justifyContent}
      style={{
        ...props.style,
      }}
    >
      {props.children}
    </FlexContainer>
  </div>
);

UICustomCardHeader.defaultProps = {
  justifyContent: 'space-between',
  children: null,
  style: {},
};

export { UICardVideo, UICard, UICardHeader, UICardBody, UICardImage, UICardFooter, UICardTitle, UICustomCardHeader };
