import React, { PureComponent } from 'react';
import Svg, { Path } from './svg';

// eslint-disable-next-line react/prefer-stateless-function
export default class ProfileIcon extends PureComponent {
  static defaultProps = { width: 24, height: 24 };

  render() {
    const { width, height, color } = this.props;
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <Path
          fill={color}
          d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z"
        />
        <Path d="M0 0h24v24H0z" fill="none" />
      </Svg>
    );
  }
}
