import { createSelector } from 'reselect';
import { getTopicFromTitleOrId } from '~/utils/helpers';

const getTopics = state => state.entities.topics;
const getActiveTopicId = state => state.ui.activeTopic;
const getCompletedExercises = state => state.auth.user.progress;
const getLessons = state => state.entities.lessons;
const getSections = state => state.entities.sections;
const getExercises = state => state.entities.exercises;

const getProgressForTopic = (topic, lessons, sections, exercises, completed) => {
  if (!topic.lessons)
    return {
      ...topic,
      totalExercises: 0,
      totalControlQuestions: 0,
      completedControlQuesstionExercises: 0,
      completedExercises: 0,
      totalScore: 0,
    };

  const lessonsInTopic = topic.lessons.map(lessonId => lessons[lessonId]);
  const sectionIdsInTopic = [].concat(...lessonsInTopic.map(lesson => lesson.sections));
  const sectionsInTopic = sectionIdsInTopic.map(sectionId => sections[sectionId]).filter(section => section);
  const allExerciseIds = [].concat(...sectionsInTopic.map(section => section.exercises));
  const allControlQuestionExerciseIds = allExerciseIds
    .map(exerciseId => ({ ...exercises[exerciseId], id: exerciseId }))
    .filter(exercise => exercise.template === 'question::OneOfFour')
    .map(exercise => exercise.id);

  const allCompletedExerciseIds = Object.keys(completed || {});
  const completedExerciseIds = allExerciseIds.filter(exerciseId => allCompletedExerciseIds.includes(exerciseId));
  const totalScore = completedExerciseIds
    .map(exerciseId => completed[exerciseId])
    .reduce(
      (total, exercise) => total + (exercise && exercise.points && exercise.points.total ? exercise.points.total : 0),
      0
    );
  const completedControlQuestionsIds = allControlQuestionExerciseIds.filter(exerciseId =>
    allCompletedExerciseIds.includes(exerciseId)
  );

  return {
    ...topic,
    totalExercises: allExerciseIds.length,
    totalControlQuestions: allControlQuestionExerciseIds.length,
    completedControlQuesstionExercises: completedControlQuestionsIds.length,
    completedExercises: completedExerciseIds.length,
    totalScore,
  };
};

export const getAllTopics = createSelector(
  [getTopics],
  todos =>
    todos
      ? Object.entries(todos)
          .map(entry => ({ ...entry[1], id: entry[0] }))
          .sort((a, b) => (a.order > b.order ? 1 : -1))
      : []
);

export const getActiveTopic = createSelector(
  [getActiveTopicId, getTopics, getLessons, getSections, getExercises, getCompletedExercises],
  (activeTopic, topics, lessons, sections, exercises, completed) =>
    getProgressForTopic(
      {
        ...getTopicFromTitleOrId(topics, activeTopic),
        id: activeTopic,
      },
      lessons,
      sections,
      exercises,
      completed
    )
);

export const getAllTopicsWithProgress = createSelector(
  [getAllTopics, getLessons, getSections, getExercises, getCompletedExercises],
  (topics, lessons, sections, exercises, completed) =>
    topics.map(topic => getProgressForTopic(topic, lessons, sections, exercises, completed))
);
