// @flow
import React, { PureComponent } from 'react';
import Svg, { Circle } from '~/icons/svg';
// eslint-disable-next-line no-unused-vars
import css from './CircleAnimation.css';

type Props = {
  ended: boolean,
  style: ?{},
  duration: number,
  width: number,
  height: number,
  play: boolean,
};

type State = {
  ended: boolean,
};

export default class CircleAnimation extends PureComponent<Props, State> {
  state = {
    ended: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.ended !== this.props.ended && this.props.ended) {
      this.resetAnimation();
    }
  }

  resetAnimation() {
    this.setState({ ended: true });
    setTimeout(() => {
      this.setState({ ended: false });
    }, 50);
  }

  render() {
    const { style, duration = 1, width = 70, height = 70, play } = this.props;
    const { ended } = this.state;

    return (
      <Svg
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 80 80"
        style={{ position: 'absolute', zIndex: 99 }}
      >
        <Circle
          className="circleAnimationProgress"
          cx="40"
          cy="40"
          r="36"
          fill="transparent"
          stroke="#fff"
          strokeWidth="6"
          style={{
            animationDuration: `${duration}s`,
            animationPlayState: play ? 'running' : 'paused',
            animationName: ended ? '' : 'circleAnimationProgress',
            ...style,
          }}
        />
      </Svg>
    );
  }
}
