import axios from 'axios';
import { firebaseGetIdToken } from '~/utils/api';

const ENDPOINT_URL = 'https://app.bonnierstrafikskola.se/api/klarna';

export const klarnaCheckout = (token, orderData, existingOrder = false) =>
  axios({
    method: 'post',
    url: `${ENDPOINT_URL}/checkout`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      orderData,
      existingOrder,
    },
  })
    .then(response => ({ success: true, response: response.data }))
    .catch(() => {
      clearCheckoutOrderId();
      return { success: false };
    });

export const klarnaConfirmation = (token, orderId) =>
  axios({
    method: 'post',
    url: `${ENDPOINT_URL}/confirmation`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      orderId,
    },
  })
    .then(response => response.data)
    .catch(err => false);

export const redeemVoucherCode = async (uid, voucherCode) => {
  const token = await firebaseGetIdToken();
  return axios({
    method: 'post',
    url: `${ENDPOINT_URL}/redeem-voucher`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      voucherCode,
      uid,
    },
  });
};

export const loadKlarnaScript = () => {
  const script = document.createElement('script');
  script.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
  script.async = true;
  document.body.appendChild(script);
};

export const getCheckoutOrderId = () => {
  if (localStorage.hasOwnProperty('klarnaPaymentsData')) {
    let klarnaPaymentsData = localStorage.getItem('klarnaCheckoutOrderId');
    try {
      klarnaPaymentsData = JSON.parse(klarnaPaymentsData);
    } catch (e) {
      klarnaPaymentsData = false;
    }
    return klarnaPaymentsData;
  }
  return false;
};

export const saveCheckoutOrderId = data => {
  try {
    localStorage.setItem('klarnaCheckoutOrderId', JSON.stringify(data));
  } catch (e) {
    console.log('cant save to local storage');
  }
};

export const clearCheckoutOrderId = () => {
  try {
    localStorage.setItem('klarnaCheckoutOrderId', '');
  } catch (e) {
    console.log('cant clear local storage');
  }
};
