import React, { Component } from 'react';
import { connect } from 'react-redux';
import { redeemVoucherCode } from '~/utils/klarna';
import { dateGetYearMonthDay } from '~/utils/helpers';
import { Alert, Container, Text, Modal, Colors, Button, Input, Loader } from '~/ui';

class VoucherModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherInput: '',
      redeemVoucherError: false,
      redeemVoucherLoading: false,
      redeemVoucherSuccess: false,
    };
  }

  handleVoucherInput = value => {
    this.setState({ voucherInput: value });
  };

  redeemVoucher = () => {
    this.setState({ redeemVoucherLoading: true, redeemVoucherError: false });
    const {
      user: { uid = false },
    } = this.props;
    const { voucherInput } = this.state;
    redeemVoucherCode(uid, voucherInput)
      .then(() => {
        this.setState({
          redeemVoucherLoading: false,
          redeemVoucherSuccess: true,
          voucherInput: '',
          redeemVoucherError: false,
        });
      })
      .catch(() => {
        this.setState({ redeemVoucherError: true, redeemVoucherLoading: false });
      });
  };

  render() {
    const { onClose, visible, user } = this.props;
    const { voucherInput, redeemVoucherError, redeemVoucherLoading, redeemVoucherSuccess } = this.state;
    const { subscriptionData } = user;

    return (
      <Container>
        <Modal
          visible={visible}
          open={visible}
          style={styles.modal}
          aria-labelledby="Kampanjkod"
          aria-describedby="Använd en kampanjkod"
          onClose={onClose}
          hideBackdrop={false}
          disableAutoFocus={false}
        >
          <Container style={{ margin: '20px auto', padding: '0 20px' }}>
            <Container
              style={{ padding: 20, margin: '20px auto', backgroundColor: '#fff', maxWidth: 420, borderRadius: 20 }}
            >
              <Text textType="p" style={{ marginTop: 20, marginBottom: 20 }}>
                Ange din kampanjkod för att lösa in mot en prenumeration.
              </Text>

              <Input
                label="Kampanjkod"
                value={voucherInput}
                type="text"
                onChange={this.handleVoucherInput}
                id="voucher"
              />

              {redeemVoucherError && (
                <Text textType="p" style={{ color: Colors.dangerColor, marginTop: 20 }}>
                  Kampanjkoden är inte giltig ;(
                </Text>
              )}

              {redeemVoucherLoading && (
                <Container style={{ display: 'flex', justifyContent: 'center', marginTop: 14 }}>
                  <Loader />
                </Container>
              )}

              <Button
                action={this.redeemVoucher}
                text="Lös in kampanjkod"
                style={{ backgroundColor: Colors.secondaryColor, marginTop: 20, marginBottom: 20 }}
                fullWidth
              />
            </Container>
          </Container>
        </Modal>

        <Alert
          open={redeemVoucherSuccess}
          approve={() => this.setState({ redeemVoucherSuccess: false }, () => onClose())}
          approveText="Ok"
          title="Giltig prenumeration"
          body={
            <>
              <Text>Vad kul! nu har du en giltig prenumeration till </Text>
              <Text textType="bold" style={{ display: 'inline' }}>
                {dateGetYearMonthDay(subscriptionData.subscriptionValidTo)}
              </Text>
            </>
          }
        />
      </Container>
    );
  }
}

const styles = {
  modal: {
    backgroundColor: 'transparent',
    paddingTop: 10,
    display: 'flex',
  },
};

export default connect(({ auth }) => ({
  uid: auth.user && auth.user.uid ? auth.user.uid : false,
  user: auth.user,
}))(VoucherModal);
