export default {
  mainPrimary: '#F44336',
  mainDark: '#433f3f',
  // primaryColor: '#E53935',
  primaryColor: '#009688',
  // primaryDarkColor: '#AB000D',
  primaryDarkColor: '#00796B',
  primaryLightColor: '#FFCDD2',
  primaryContrastColor: '#FDFDFD',
  secondaryColor: '#009688',
  secondaryDarkColor: '#00796B',
  secondaryLightColor: '#B2DFDB',
  secondaryContrastColor: '#FDFDFD',
  textColor: '#433f3f',
  textSecondaryColor: '#757575',
  menuTextColor: '#f8e8e9',
  alternativTextColor: '#03A9F4',
  successColor: '#277656',
  warningColor: '#FFCA28',
  dangerColor: '#d32f2f',
  screenColor: '#fff',
  blue: '#2196F3',
  darkBlue: '#7986CB',
  green: '#4CAF50',
  yellow: '#FFF176',
  white: '#FDFDFD',
  black: '#212121',
  red: '#E57373',
  darkGrey: '#757575',
  textGrey: '#212121',
  lightGrey: '#e3e3e3',
  gradientStart: '#A50008',
  gradientStop: '#ed0f1f',
  transparent: 'transparent',
  surfaceDark: '#f5f5f5',
  headerColor: '#1E2121',
};
