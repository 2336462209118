import React, { Component } from 'react';
import Svg, { G, Polygon, Path, Rect } from './svg';

export default class TwoToneThumbsDownIcon extends Component {
  static defaultProps = { width: 24, height: 24 };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.color !== nextProps.color ||
      this.props.width !== nextProps.width ||
      this.props.height !== nextProps.height
    );
  }

  render() {
    return (
      <Svg
        version="1.1"
        x="0"
        y="0"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
        enableBackground="new 0 0 24 24"
      >
        <G id="Bounding_Boxes">
          <G id="ui_x5F_spec_x5F_header_copy_3" />
          <Path fill="none" d="M0,0h24v24H0V0z" />
          <Path fill="none" d="M0,0h24v24H0V0z" />
        </G>
        <G id="Duotone">
          <G id="ui_x5F_spec_x5F_header_copy_2" />
          <G>
            <Polygon fill={this.props.color} opacity="0.3" points="3,12 3,14 12,14 10.66,19.34 15,15 15,5 6,5 		" />
            <Path
              fill={this.props.color}
              d="M15,3H6C5.17,3,4.46,3.5,4.16,4.22l-3.02,7.05C1.05,11.5,1,11.74,1,12v2c0,1.1,0.9,2,2,2h6.31l-0.95,4.57l-0.03,0.32
			c0,0.41,0.17,0.79,0.44,1.06L9.83,23l6.59-6.59C16.78,16.05,17,15.55,17,15V5C17,3.9,16.1,3,15,3z M15,15l-4.34,4.34L12,14H3l0-2
			l3-7h9V15z"
            />
            <Rect fill={this.props.color} x="19" y="3" width="4" height="12" />
          </G>
        </G>
      </Svg>
    );
  }
}
