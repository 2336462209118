import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navToAction } from '~/store/actions/navActions';
import { Touchable } from '~/ui';

const NavButton = ({ onClick, children, navTo, style }) => (
  <Touchable
    transparent="transparent"
    style={{ minWidth: 0, padding: 5, ...style }}
    action={() => onClick(navTo)}
    text="PROFIL"
  >
    {children}
  </Touchable>
);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onClick: navTo => navToAction(navTo),
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(NavButton);
