import React, { Component } from 'react';
import Svg, { Path } from './svg';

export default class CloseCircleOutelineIcon extends Component {
  static defaultProps = { width: 24, height: 24 };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.color !== this.props.color ||
      nextProps.width !== this.props.width ||
      nextProps.height !== this.props.height
    );
  }

  render() {
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 24 24">
        <Path d="M0 0h24v24H0z" fill="none" />
        <Path
          fill={this.props.color}
          d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        />
      </Svg>
    );
  }
}
