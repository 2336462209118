import { firebase } from '~/utils/api/firebase';

export const firebaseGetCurrentUserId = () => (firebase.auth().currentUser || {}).uid;

export const firebaseGetIdToken = () => {
  if (firebase.auth().currentUser) {
    return firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => idToken)
      .catch(() => false);
  }
  return new Promise(resolve => resolve(false));
};

export const firebaseGetFile = file => {
  const imageRef = firebase.storage().ref(file);
  return imageRef
    .getDownloadURL()
    .then(url => url)
    .catch(error => error);
};

export const firebaseAuthSubscriber = (callbackFunc = () => {}) => {
  firebase.auth().onAuthStateChanged(user => {
    callbackFunc(user);
  });
};

export const firebaseAuthSignInWithEmail = (email, pass) =>
  firebase.auth().signInAndRetrieveDataWithEmailAndPassword(email, pass);

export const firebaseAuthSignInWithCustomToken = (token) =>
  firebase.auth().signInWithCustomToken(token)

export const firebaseAuthResetEmail = emailAddress => firebase.auth().sendPasswordResetEmail(emailAddress);

export const firebaseAuthSignOut = () => firebase.auth().signOut();

export const firebaseCreateUserWithEmail = (email, pass) => firebase.auth().createUserWithEmailAndPassword(email, pass);

export const firebaseWriteUserComment = (userId, comment) =>
  firebase
    .database()
    .ref(`users/${userId}/comments/${comment.id}`)
    .set(comment);

export const firebaseListenForUserSubscription = (userId, callback = () => {}) => {
  const subscriptionRef = firebase.database().ref(`userSubscriptions/${userId}/subscriptionData`);

  subscriptionRef.on('value', async snapshot => {
    let validSubscription = false;
    const subscriptionValidTo = snapshot.val() 
      ? Math.max(snapshot.val().subscriptionValidTo ?? 0, snapshot.val().manualExpirationDate ?? 0)
      : 0

    if (subscriptionValidTo) {
      validSubscription = await firebaseGetTimeAndCheckValidSubscription(subscriptionValidTo);
    }

    callback({ subscriptionData: snapshot.val(), validSubscription, loadedSubscriptionData: true });
  });
};
export const firebaseListenForUserData = (userId, callback = () => {}) => {
  const userRef = firebase.database().ref(`users/${userId}`);
  userRef.on('value', snapshot => {
    callback(snapshot.val());
  });
};

export const firebaseUpdateUserProgress = (userId, exerciseId, data) => {
  const ref = firebase.database().ref(`users/${userId}/progress/${exerciseId}`);
  return ref.set(data);
};

export const firebaseUpdateUserControlQuestion = (userId, exerciseId, data) => {
  const ref = firebase.database().ref(`users/${userId}/controlQuestions/${exerciseId}`);
  return ref.set(data);
};

export const firebaseSaveUserKnowledgeTest = (userId, score) => {
  const ref = firebase.database().ref(`users/${userId}/knowledgeTest`);
  return ref.push(score);
};

export const firebaseGetUserKnowledgeTests = userId => {
  const ref = firebase.database().ref(`users/${userId}/knowledgeTest`);
  return ref.once('value');
};

export const firebaseUpdateUserInfo = (userId, userData) =>
  firebase
    .database()
    .ref(`users/${userId}/userData`)
    .set(userData);

export const firebaseEndUserSubscription = userId => {
  const ref = firebase.database().ref(`users/${userId}/subscription/subscriptionStatus`);
  return ref.set('UNSUBSCRIBED');
};

export const firebaseRemoveFavouriteItem = (userId, itemId) =>
  firebase
    .database()
    .ref(`users/${userId}/favourites/${itemId}`)
    .remove();

export const listenForNotification = (callback = () => {}) => {
  const FCM = firebase.messaging();
  FCM.requestPermissions();
  FCM.getToken().then(callback);
};

export const firebaseGetTime = () =>
  firebase
    .database()
    .ref('.info/serverTimeOffset')
    .once('value')
    .then(snap => {
      const offset = snap.val();
      const estimatedServerTimeMs = new Date().getTime() + Math.round(offset);
      return estimatedServerTimeMs;
    });

export const firebaseGetTimeAndCheckValidSubscription = (subscriptionDate = 0) => {
  const excludeTimeAndGetTimestamp = date => {
    const timestampDate = new Date(date);
    return new Date(
      `${timestampDate.getFullYear()}-${timestampDate.getMonth() + 1 < 10 ? '0' : ''}${timestampDate.getMonth() + 1}-${
        timestampDate.getDate() < 10 ? '0' : ''
      }${timestampDate.getDate()}`
    ).getTime();
  };

  return firebaseGetTime().then(
    resp => excludeTimeAndGetTimestamp(subscriptionDate) > excludeTimeAndGetTimestamp(resp)
  );
};

export const firebaseGetProducts = () =>
  firebase
    .database()
    .ref(`products`)
    .once('value')
    .then(snap => snap.val());

export const firebaseGetBaseContent = () =>
  firebase
    .database()
    .ref(`content/baseContent`)
    .once('value')
    .then(snap => snap.val());

export const firebaseGetSubscriptionContent = () =>
  firebase
    .database()
    .ref(`content/subscriptionContent`)
    .once('value')
    .then(snap => snap.val());

export const firebaseGetInstructions = () =>
  firebase
    .database()
    .ref('content/instructions')
    .once('value')
    .then(snap => snap.val());

export const firebaseMarkInstructionAsDoNotShow = (userId, template) =>
  firebase
    .database()
    .ref(`users/${userId}/instructions/`)
    .update({ [template]: { doNotShow: true } });

export const makeFirebaseUserActiveStatusPinger = userStatusRef => async user => {
  if (user) {
    const time = await firebaseGetTime();
    await firebase
      .database()
      .ref(`${userStatusRef}/${user.uid}`)
      .set(time);
  }

  return user;
};
