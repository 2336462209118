import { MDAO_TOKEN_QUERY_KEY } from '~/utils/constants';

const firebaseStorageUrl = 'https://storage.googleapis.com/bonniers-trafikskola.appspot.com/';

export const debouncer = (func = () => {}, wait = 200) => {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export const chunkArray = (arr, chunkSize, acc = []) =>
  arr.length > chunkSize
    ? chunkArray(arr.slice(chunkSize), chunkSize, [...acc, arr.slice(0, chunkSize)])
    : [...acc, arr];

export const fileResourceLocation =
  'https://firebasestorage.googleapis.com/v0/b/bonniers-trafikskola.appspot.com/o/resources%2F';

export const arrayOfRandomNumbers = (arrayLength, randomNumberSpan) => {
  let randomNumbers = [];
  for (let i = 0; i < arrayLength; i++) {
    const randomNr = Math.floor(Math.random() * randomNumberSpan);
    if (randomNumbers.includes(randomNr)) {
      i--;
    } else {
      randomNumbers = [...randomNumbers, randomNr];
    }
  }
  return randomNumbers;
};

export const shuffleArray = arr =>
  arr && Array.isArray(arr)
    ? arr
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1])
    : [];

export const getTopicFromTitleOrId = (topics, topicTitle) =>
  topics[topicTitle]
    ? topics[topicTitle]
    : Object.keys(topics)
        .map(topicKey => ({ ...topics[topicKey], topicId: topicKey }))
        .find(topic => topic.title === topicTitle);

export const getFirebaseThumbnailUrl = thumbnail => `${firebaseStorageUrl}thumbnails/${thumbnail}`;

export const getFirebaseImageUrl = image => `${firebaseStorageUrl}images/${image}`;

export const getFirebaseVideoUrl = video => `${firebaseStorageUrl}videos/${video}`;

export const getFirebaseAudioUrl = audio => `${firebaseStorageUrl}audio/${audio}`;

export const getFirebaseSvgOrPngUrl = (image = '') => {
  const imageName = image.split('.')[0];
  if (typeof document !== 'undefined') {
    return `${firebaseStorageUrl}images/${imageName}.svg`;
  }
  return `${firebaseStorageUrl}images/${imageName}.jpg`;
};

export const getRandomKnowledgeTestExercises = allQuestions => {
  const allQuestionsArray = Object.keys(allQuestions).map(questionId => ({
    ...allQuestions[questionId],
    questionId,
  }));
  const fordonskannedom = shuffleArray(
    allQuestionsArray.filter(question => question.subjectName === 'Fordonskännedom/manövrering'),
  ).splice(0, 7);
  const environment = shuffleArray(allQuestionsArray.filter(question => question.subjectName === 'Miljö')).splice(0, 5);
  const traficSafety = shuffleArray(
    allQuestionsArray.filter(question => question.subjectName === 'Trafiksäkerhet'),
  ).splice(0, 16);
  const traficRules = shuffleArray(
    allQuestionsArray.filter(question => question.subjectName === 'Trafikregler'),
  ).splice(0, 32);
  const personalConditions = shuffleArray(
    allQuestionsArray.filter(question => question.subjectName === 'Personliga förutsättningar'),
  ).splice(0, 5);
  const randomFiveQuestions = shuffleArray(
    allQuestionsArray.filter(question => question.subjectName === 'Körkortsutbildningen'),
  ).splice(0, 5);

  return [
    ...fordonskannedom,
    ...environment,
    ...traficSafety,
    ...traficRules,
    ...personalConditions,
    ...randomFiveQuestions,
  ];
};

export const isIOSSafari = () =>
  !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) &&
  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
  !window.MSStream;

export const isIphoneX = () => {
  const ratio = window.devicePixelRatio || 1;
  const screen = {
    width: window && window.screen ? window.screen.width * ratio : 0,
    height: window && window.screen ? window.screen.height * ratio : 0,
  };
  return isIOSSafari() && screen.width === 1125;
};

export const emailValidation = email => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const phoneValidation = phone => /^((((0{2}?)|(\+){1})46)|0)7[\d]{8}/.test(phone);

export const getExerciseTitleFromTemplate = template => {
  switch (template) {
    case 'interactive::AssociateWordsToCorrectSentence':
      return 'Para ihop ord med fraser';
    case 'interactive::WhichPhraseFitsWithTheText':
      return 'Välj det alternativet som stämmer med texten';
    case 'interactive::RightOrWrong':
      return 'Sant eller falskt?';
    case 'interactive::TrueOrFalse':
      return 'Sant eller falskt?';
    case 'interactive::FinishTheSentences':
      return 'Fyll i meningarna';
    case 'interactive::BuildSentencesUsingWords':
      return 'Klicka på orden i rätt ordning';
    case 'interactive::WordGrouping':
      return 'Para ihop orden';
    case 'interactive::DragAndDropWords':
      return 'Para ihop ord med bilder';
    case 'interactive::PickRightObject':
      return 'Klicka på rätt vägmärke';
    case 'interactive::ClickOnTheRightPhrase':
      return 'Klicka på rätt fras som stämmer överens med bilden';
    case 'interactive::ClickOnTheRightPartOfTheImage':
      return 'Klicka på rätt fras som stämmer överens med bilden';
    case 'interactive::SortInRightOrder':
      return 'Sortera i rätt ordning';
    case 'question::OneOfFour':
      return 'Klara denna sektion genom att svara rätt på minst 3/4';
    // return 'Svara rätt på minst 3/4 av frågorna och klara kapitlet';
    default:
      return '';
  }
};

export const addZeroes = num => {
  let value = Number(num);
  const res = `${num}`.split('.');
  if (res.length === 1 || res[1].length < 3) {
    value = value.toFixed(2);
  }
  return value;
};

export const dateGetYearMonthDay = date => {
  const dateObj = new Date(date);
  return `${dateObj.getFullYear()}-${dateObj.getMonth() < 9 ? '0' : ''}${dateObj.getMonth() + 1}-${
    dateObj.getDate() < 10 ? '0' : ''
  }${dateObj.getDate()}`;
};

export const getMDAOTokenFromLocation = location => {
  const query = location && location.search ? new URLSearchParams(location.search) : null;

  return query && query.get(MDAO_TOKEN_QUERY_KEY) ? query.get(MDAO_TOKEN_QUERY_KEY) : null;
};
