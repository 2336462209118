import React from 'react';
import { Text, Button, Card, CardImage, Container } from '~/ui';
import TopicProgress from '~/components/topicPanel/TopicProgress';

const TopicPanel = ({ topic, image, openTopic, approvedKnowledgeTests }) => (
  <Card
    style={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
      width: '100%',
      marginBottom: 10,
      flex: 1,
    }}
  >
    <Container
      style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20, overflow: 'hidden', height: 200, width: '100%' }}
    >
      <CardImage
        src={image || ''}
        style={{
          height: 200,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          width: '100%',
          overflow: 'hidden',
          resizeMode: 'cover',
        }}
      />
    </Container>

    <Container style={{ padding: 16 }}>
      <Text textType="h3" style={{ color: topic.color, marginBottom: 12 }}>
        {topic.title}
      </Text>
      <Text>{topic.description}</Text>
    </Container>

    <Container style={{ marginTop: 'auto' }}>
      {/* <Container
        style={{
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        {topic.title !== 'Kunskapsprovet' ? (
          // Progress bar on each card on the page with all topics
          <TopicProgress
            colorDark={topic.colorDark}
            color={topic.color}
            colorSecondary={topic.colorSecondary}
            completedExercises={topic.completedControlQuesstionExercises}
            totalExercises={topic.totalControlQuestions}
          />
        ) : (
          <Container style={{ height: 40, paddingTop: 2 }}>
            <Text textType="small">
              {approvedKnowledgeTests > 0
                ? `Godkända kunskapsprov: ${approvedKnowledgeTests}`
                : 'Inga godkända kunskapsprov'}
            </Text>
          </Container>
        )}
      </Container> */}

      <Container
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          padding: 16,
          paddingRight: 20,
          paddingTop: 16,
          marginBottom: 8,
        }}
      >
        <Button
          small
          action={openTopic}
          text={topic.completedExercises > 0 ? 'Fortsätt' : 'Starta'}
          style={{ marginLeft: 'auto' }}
          color="secondary"
        />
      </Container>
    </Container>
  </Card>
);

export default TopicPanel;
