import { Component } from 'react';
import { connect } from 'react-redux';
import { setActiveTopicAction, setActiveSectionAction, setActiveExerciseAction } from '~/store/actions/uiActions';
import { bindActionCreators } from 'redux';

class UrlToReduxMapper extends Component {
  componentWillMount() {
    this.updateRedux();
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.match.params.topicId !== this.props.match.params.topicId ||
      nextProps.match.params.sectionId !== this.props.match.params.sectionId ||
      nextProps.match.params.exerciseId !== this.props.match.params.exerciseId
    );
  }

  componentWillUpdate() {
    this.updateRedux();
  }

  updateRedux() {
    if (this.props.match.params.exerciseId) {
      this.props.setActiveExerciseAction(this.props.match.params.exerciseId);
    } else if (this.props.match.params.sectionId) {
      this.props.setActiveSectionAction(this.props.match.params.sectionId);
    } else if (this.props.match.params.topicId) {
      this.props.setActiveTopicAction(this.props.match.params.topicId);
    } else {
      this.props.setActiveTopicAction(null);
    }
  }

  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveTopicAction,
      setActiveSectionAction,
      setActiveExerciseAction,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(UrlToReduxMapper);
