import React from 'react';
import { connect } from 'react-redux';
import { Container, Loader } from '~/ui';

const HaltUntilContentLoaded = ({ loadingSubscriptionContent, loadingBaseContent, requireSubscribed, children }) => {
  if (loadingBaseContent || (loadingSubscriptionContent && requireSubscribed)) {
    return (
      <Container
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </Container>
    );
  }
  return children;
};

const mapStateToProps = state => ({
  loadingBaseContent: state.entities.loadingBaseContent,
  loadingSubscriptionContent: state.entities.loadingSubscriptionContent,
});

export default connect(mapStateToProps)(HaltUntilContentLoaded);
