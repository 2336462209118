import React from 'react';
import { Text } from '~/ui';
import { ProfileIcon } from '~/icons';
import NavButton from '~/components/NavButton';

const ProfileButton = ({ variant, color, style }) => {
  if (variant === 'icon') {
    return (
      <NavButton style={style} navTo="profile">
        <ProfileIcon color={color} />
      </NavButton>
    );
  }

  return (
    <NavButton style={{ paddingLeft: 5, paddingRight: 5, ...style }} navTo="profile" text="PROFIL">
      <Text style={{ color: '#fff' }}>PROFIL</Text>
    </NavButton>
  );
};

export default ProfileButton;
