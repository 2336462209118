// @flow
import React, { PureComponent } from 'react';
import Svg, { Path } from './svg';

type Props = {
  width?: number,
  height?: number,
  color?: string,
};

export default class TrendingUpIcon extends PureComponent<Props> {
  static defaultProps = {
    width: 24,
    height: 24,
    color: 'black',
  };

  render() {
    const { width, height, color } = this.props;
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <Path fill={color} d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" />
        <Path d="M0 0h24v24H0z" fill="none" />
      </Svg>
    );
  }
}
