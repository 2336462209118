// @flow
import React from 'react';
import Svg, { G, Path } from './svg';

type Props = {
  width?: number,
  height?: number,
  color?: string,
};

export default class CarIcon extends React.PureComponent<Props> {
  static defaultProps = {
    width: 24,
    height: 24,
    color: null,
  };

  render() {
    const { width, height, color } = this.props;
    return (
      <Svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
        <G id="Page-1" stroke="none" strokeWidth="1" fill="none">
          <G id="outline-slow_motion_video-24px" transform="translate(-2.000000, -2.000000)">
            <Path
              fill={color}
              d="M11,4.07 L11,2.05 C8.99,2.25 7.16,3.05 5.68,4.26 L7.1,5.69 C8.21,4.83 9.54,4.25 11,4.07 Z M5.69,7.1 L4.26,5.68 C3.05,7.16 2.25,8.99 2.05,11 L4.07,11 C4.25,9.54 4.83,8.21 5.69,7.1 Z M4.07,13 L2.05,13 C2.25,15.01 3.05,16.84 4.26,18.32 L5.69,16.89 C4.83,15.79 4.25,14.46 4.07,13 Z M5.68,19.74 C7.16,20.95 9,21.75 11,21.95 L11,19.93 C9.54,19.75 8.21,19.17 7.1,18.31 L5.68,19.74 Z M22,12 C22,17.16 18.08,21.42 13.05,21.95 L13.05,19.93 C16.97,19.41 20,16.05 20,12 C20,7.95 16.97,4.59 13.05,4.07 L13.05,2.05 C18.08,2.58 22,6.84 22,12 Z"
              id="Shape"
              fillRule="nonzero"
            />
          </G>
        </G>
      </Svg>
    );
  }
}
