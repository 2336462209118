import React from 'react';
import Button from '@material-ui/core/Button';

export default ({
  action,
  fullWidth,
  text,
  disabled,
  style,
  small,
  type,
  color = 'primary',
  variant = 'contained',
}) => {
  const buttonprops = {
    onClick: action,
    fullWidth,
  };

  return (
    <Button
      style={{
        ...(variant !== 'text' ? { borderRadius: 16, paddingTop: 12, paddingBottom: 12 } : {}),
        ...(small ? { paddingTop: 5, paddingBottom: 5, paddingRight: 24, paddingLeft: 24, borderRadius: 99 } : {}),
        ...style,
      }}
      color={color}
      variant={variant}
      disabled={disabled}
      type={type || 'button'}
      {...buttonprops}
    >
      {text}
    </Button>
  );
};
