// @flow
import React from 'react';
import Svg, { Path } from './svg';

type Props = {
  width?: number,
  height?: number,
  color?: string,
};

export default class ReadIcon extends React.PureComponent<Props> {
  static defaultProps = {
    width: 24,
    height: 24,
    color: null,
  };

  render() {
    const { width, height, color } = this.props;
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <Path fill="none" d="M0 0h24v24H0V0z" />
        <Path
          fill={color}
          d="M21 4H3c-1.1 0-2 .9-2 2v13c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM3 19V6h8v13H3zm18 0h-8V6h8v13zm-7-9.5h6V11h-6zm0 2.5h6v1.5h-6zm0 2.5h6V16h-6z"
        />
      </Svg>
    );
  }
}
