import { firebaseGetCurrentUserId, firebaseMarkInstructionAsDoNotShow, firebaseGetInstructions } from '~/utils/api';
import { fetchInstructions, loadedInstructions } from '../actions/instructionActions';

const middleware = store => next => {
  next(fetchInstructions());
  firebaseGetInstructions().then(instructions => {
    next(loadedInstructions(instructions));
  });

  return async action => {
    next(action);

    const user = await firebaseGetCurrentUserId();
    if (!user) return;

    switch (action.type) {
      case 'INSTRUCTIONS_MARK_DO_NOT_SHOW':
        firebaseMarkInstructionAsDoNotShow(user, action.payload.exerciseTemplate);
        break;

      default:
        break;
    }
  };
};

export default middleware;
