import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const style = {
  container: {
    position: 'relative',
  },
  refresh: {
    display: 'inline-block',
    position: 'relative',
    boxShadow: 'none',
    background: 'none',
  },
};

const large = 50;
const small = 34;

const Loader = props => (
  <div style={style.container}>
    <CircularProgress
      size={props.size === 'large' ? large : small}
      color="primary"
      status="loading"
      style={style.refresh}
    />
  </div>
);

export default Loader;
