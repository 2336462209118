import React from 'react';
import { Container } from '~/ui';

const UIVideo = props => (
  <Container smallStyle={props.smallStyle} style={props.style}>
    <video
      controls
      controlsList="nodownload"
      autoPlay
      onEnded={props.onEnded}
      style={{ width: '100%', maxWidth: '100%' }}
    >
      <source src={props.uri} type="video/mp4" />
    </video>
  </Container>
);

export default UIVideo;
