import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../colors';

const UILinearGradient = props => (
  <div
    style={{
      display: 'block',
      backgroundImage: `linear-gradient(to bottom right, ${Colors.gradientStart} 0%, ${Colors.gradientStop} 100%)`,
      ...props.style,
    }}
  >
    {props.children}
  </div>
);

UILinearGradient.propTypes = {
  style: PropTypes.shape({}),
  children: PropTypes.node,
};

UILinearGradient.defaultProps = {
  style: {},
  children: null,
};

export default UILinearGradient;
