import React from 'react';
import { Text } from '~/ui';
import { SearchIcon } from '~/icons';
import NavButton from '~/components/NavButton';

const SearchButton = ({ variant, color, style }) => {
  if (variant === 'icon') {
    return (
      <NavButton style={style} navTo="search">
        <SearchIcon color={color} />
      </NavButton>
    );
  }

  return (
    <NavButton style={{ paddingLeft: 5, paddingRight: 5, ...style }} navTo="search" text="SÖK">
      <Text style={{ color: '#fff' }}>SÖK</Text>
    </NavButton>
  );
};

export default SearchButton;
