import React from 'react';
import LoginScreen from '~/screens/Login';
import HomeScreen from '~/screens/Home';
import PageNotFound from '~/screens/pageNotFound';

import {Text, Container} from '~/ui';
// import {Logo} from '~/icons';

import Logo from '~/img/MDA_Logo.png';

export const routeList = [
  {
    webUrl: '/login',
    component: LoginScreen,
    key: 'login',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
  {
    webUrl: '/signup',
    key: 'signup',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
  {
    webUrl: '/reset-password',
    key: 'resetPassword',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
  {
    webUrl: '/profile',
    key: 'profile',
    title: (
      <Text textType="h2" style={{color: 'white'}}>
        Profil
      </Text>
    ),
  },
  {
    webUrl: '/search',
    key: 'search',
    tite: <Text textType="h2">Sök</Text>,
  },
  {
    webUrl: '/topics/:topicId',
    key: 'topic',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
  {
    webUrl: '/',
    component: HomeScreen,
    key: 'home',
    title: (
      <Container style={{width: 167}} smallStyle={{width: 130}}>
        {/* <Logo style={{width: '100%'}} /> */}
        <img
          style={{height: 130, width: 100, resizeMode: 'contain'}}
          src={Logo}
        />
      </Container>
    ),
  },
  {
    webUrl: '/topics/:topicId/:sectionId',
    key: 'section',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
  {
    webUrl: '/topics/:topicId/:sectionId/:exerciseId',
    key: 'exercise',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
  {
    webUrl: '/topics/Kunskapsprovet/kunskapsprov',
    key: 'knowledgeTest',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
  {
    webUrl: '/checkout',
    key: 'checkout',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
  {
    webUrl: '/confirmation',
    key: 'confirmation',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
  {
    webUrl: '/page-not-found',
    component: PageNotFound,
    key: 'pageNotFound',
    title: <Text textType="h2">My Driving Academy Online</Text>,
  },
];

export const getRoute = key => routeList.find(x => x.key === key);
