import { navToFunction } from '~/routes/navigation';
import { setActiveExerciseAction, setActiveTopicAction } from '~/store/actions/uiActions';
import { getNextExercise, getNextTopic, getNextSection } from '~/store/selectors/nextExerciseSelectors';

const navToAction = (routeName, params = {}, replace) => dispatch => {
  dispatch(navToFunction(routeName, params, replace));
};

const goToNextExerciseAction = () => (dispatch, getState) => {
  const state = getState();
  const nextExercise = getNextExercise(state);
  const nextSectionId = getNextSection(state);
  const nextTopicId = getNextTopic(state);
  const { activeSection, activeTopic } = state.ui;

  if (nextExercise.nextExerciseId) {
    dispatch(setActiveExerciseAction(nextExercise.nextExerciseId));
    dispatch(
      navToAction('exercise', {
        topicId: activeTopic,
        exerciseId: nextExercise.nextExerciseId,
        sectionId: nextExercise.exerciseInNextSection ? nextSectionId : activeSection,
      })
    );
  } else if (nextTopicId) {
    dispatch(navToAction('topic', { topicId: nextTopicId }));
    dispatch(setActiveTopicAction(nextTopicId));
  }
};

export { navToAction, goToNextExerciseAction };
