import React, { PureComponent } from 'react';
import Loader from '../loader';
import { ArrowLeftIcon, ArrowRightIcon } from '~/icons';
import { Colors } from '~/ui';

class SwiperWrapper extends PureComponent {
  swiper = null;

  componentDidMount() {
    if (this.props.views && this.props.views.length > 0 && window.Swiper) {
      this.renderSwiper();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.views && prevProps.views.length !== this.props.views.length && this.props.views.length > 0) {
      this.renderSwiper();
    }
  }

  renderSwiper() {
    const { onSwipe, swipeEnabled = true, arrows = false, numberOfWebSlides, lazyLoad = false, settings } = this.props;
    const lazyLoadSlides = (settings && settings.lazyLoad) || lazyLoad;
    this.swiper = new window.Swiper('.swiper-container', {
      navigation: {
        nextEl: arrows ? '.swiper-button-next' : '',
        prevEl: arrows ? '.swiper-button-prev' : '',
      },
      on: {
        slideChange: () => {
          if (onSwipe) onSwipe(this.swiper.realIndex);
        },
      },
      lazy: lazyLoadSlides ? { loadPrevNext: true } : false,
      allowTouchMove: swipeEnabled,
      parallax: true,
      simulateTouch: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 20,
      breakpointsInverse: true,
      longSwipesRatio: 0.2,
      longSwipesMs: 50,
      shortSwipes: false,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1300: {
          slidesPerView: numberOfWebSlides || 1,
          spaceBetween: 20,
        },
      },
    });
  }

  next() {
    if (this.swiper) this.swiper.slideNext();
  }

  prev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  goTo(index) {
    this.swiper.slideTo(index);
  }

  render() {
    const { views, arrows = false, sliderWidth = '100%', arrowColor } = this.props;

    if (views.length === 0) {
      return <Loader />;
    }

    return (
      <div className="swiper-container" style={{ width: '100%' }}>
        <div className="swiper-wrapper">
          {views.map(view => (
            <div className="swiper-slide" style={{ width: sliderWidth }} key={view.key}>
              {view.content}
            </div>
          ))}
        </div>
        <div className="swiper-pagination" />

        {arrows ? (
          <>
            <div className="swiper-button-prev swiper-button-black" style={{ backgroundImage: 'none', left: 0 }}>
              <ArrowLeftIcon color={arrowColor || Colors.secondaryColor} width={27} height={44} />
            </div>
            <div className="swiper-button-next swiper-button-black" style={{ backgroundImage: 'none', right: 0 }}>
              <ArrowRightIcon color={arrowColor || Colors.secondaryColor} width={27} height={44} />
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default SwiperWrapper;
