import React from 'react';
import { sharedProps } from './index';

const UIImage = props => (
  <img
    src={props.srcImage ? props.srcImage : props.src}
    alt=""
    onLoad={() => (props.onLoad ? props.onLoad() : null)}
    style={{
      ...props.style,
    }}
  />
);

UIImage.propTypes = sharedProps;

UIImage.defaultProps = {
  src: '',
  srcImage: null,
};

export default UIImage;
