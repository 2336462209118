import { connect } from 'react-redux';
import { getActiveTopic } from '~/store/selectors/topicSelectors';
import { bindActionCreators } from 'redux';
import TopicMenuContent from './TopicMenuContent';
import { navToAction } from '~/store/actions/navActions';
import { setActiveSectionAction } from '~/store/actions/uiActions';

const mapStateToProps = state => ({
  topic: getActiveTopic(state),
});

const openSection = (topicId, sectionId, sectionTitle) => dispatch => () => {
  dispatch(setActiveSectionAction(sectionId));
  dispatch(
    navToAction('section', {
      topicId,
      sectionId,
      nativeParams: {
        title: sectionTitle,
      },
    })
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openSection,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicMenuContent);
