import React, { Component } from 'react';
import Svg, { Path } from './svg';

export default class CheckCircleOutlineIcon extends Component {
  static defaultProps = { width: 24, height: 24 };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.color !== this.props.color ||
      nextProps.width !== this.props.width ||
      nextProps.height !== this.props.height
    );
  }

  render() {
    return this.props.filled ? (
      <Svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 24 24">
        <Path fill="none" d="M0 0h24v24H0V0z" />
        <Path
          fill={this.props.color || '#000'}
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"
        />
      </Svg>
    ) : (
      <Svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 24 24">
        <Path
          fill={this.props.color}
          d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        />
      </Svg>
    );
  }
}
