import React, {Component} from 'react';
import {connect} from 'react-redux';
import {navToAction} from '~/store/actions/navActions';
// import BTLogo from '~/assets/images/BT-logo.png';
import BTLogo from '~/assets/images/MDA_Logo_Svart.png';
import LoginForm from '~/components/LoginForm';
import {Text, Colors, Container, ScrollView, Image, Link} from '~/ui';

class LoginScreen extends Component {
  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate() {
    this.checkAuth();
  }

  checkAuth() {
    if (this.props.auth.loggedIn) {
      this.props.dispatch(navToAction('home', {nativeReplace: true}));
    }
  }

  signup() {
    this.props.dispatch(navToAction('signup', {}));
  }

  render() {
    return (
      <ScrollView style={styles.scrollView} keyboardShouldPersistTaps="handled">
        <Container
          style={styles.container}
          mediumStyle={{backgroundColor: Colors.mainDark}}>
          <Container
            style={styles.card}
            mediumStyle={{paddingTop: 42, paddingBottom: 42}}>
            <Container style={styles.cardContent} mediumStyle={{maxWidth: 350}}>
              <Image
                heightDimention={0.32}
                style={styles.logo}
                srcImage={BTLogo}
                alt="My Driving Academy Online"
              />

              <LoginForm />

              <Container
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}>
                {typeof document !== 'undefined' && (
                  <Link action={() => this.signup()}>
                    <Text style={{color: Colors.primaryColor, fontSize: 12}}>
                      Skapa nytt konto
                    </Text>
                  </Link>
                )}
                <Link
                  action={() =>
                    this.props.dispatch(navToAction('resetPassword'))
                  }>
                  <Text style={{color: Colors.primaryColor, fontSize: 12}}>
                    Glömt lösenordet?
                  </Text>
                </Link>
              </Container>
            </Container>
          </Container>
        </Container>
      </ScrollView>
    );
  }
}

const styles = {
  scrollView: {
    display: 'flex',
    flex: 1,
    backgroundColor: Colors.surfaceDark,
  },
  container: {
    width: '100%',
    backgroundColor: Colors.surfaceDark,
    display: 'flex',
    flex: 1,
  },
  card: {
    maxWidth: 550,
    width: '100%',
    margin: 'auto',
    paddingTop: 40,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: Colors.surfaceDark,
    borderRadius: 20,
  },
  cardContent: {
    width: '100%',
    margin: 'auto',
  },
  logo: {
    width: '100%',
    marginBottom: 40,
  },
  textField: {
    marginBottom: 20,
  },
  loginButton: {
    height: 45,
    marginBottom: 10,
    marginTop: 15,
  },
};

export default connect(state => ({auth: state.auth}))(LoginScreen);
