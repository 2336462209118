import { createSelector } from 'reselect';
import { getTopicFromTitleOrId } from '~/utils/helpers';

const getTopics = state => state.entities.topics;
const getLessons = state => state.entities.lessons;
const getSections = state => state.entities.sections;
const getActiveTopicId = state => state.ui.activeTopic;
const getActiveLessonId = state => state.ui.activeLesson;
const activeSectionId = state => state.ui.activeSection;
const activeExerciseId = state => state.ui.activeExercise;

export const getAllTopics = createSelector(
  [getTopics],
  todos => Object.entries(todos).map(entry => ({ ...entry[1], id: entry[0] }))
);

export const getNextSection = createSelector(
  [getLessons, getActiveLessonId, activeSectionId],
  (lessons, activeLesson, activeSection) => {
    if (
      activeLesson &&
      activeSection &&
      lessons[activeLesson].sections.indexOf(activeSection) < lessons[activeLesson].sections.length - 1
    ) {
      return lessons[activeLesson].sections[lessons[activeLesson].sections.indexOf(activeSection) + 1];
    }
    return null;
  }
);

export const getNextLesson = createSelector(
  [getAllTopics, getActiveTopicId, getActiveLessonId],
  (topics, activeTopic, activeLesson) => {
    const activeTopicObject = getTopicFromTitleOrId(topics, activeTopic);
    if (
      activeTopic &&
      activeLesson &&
      activeTopicObject &&
      activeTopicObject.lessons &&
      activeTopicObject.lessons.indexOf(activeLesson) < activeTopicObject.lessons.length - 1
    ) {
      return activeTopicObject.lessons[activeTopicObject.lessons.indexOf(activeLesson) + 1];
    }
    return null;
  }
);

export const getNextTopic = createSelector(
  [getAllTopics, getActiveTopicId],
  (topics, activeTopic) => {
    const sortTopicsByOrder = topics.sort((topicA, topicB) => topicA.order - topicB.order);
    const activeTopicId = getTopicFromTitleOrId(sortTopicsByOrder, activeTopic).topicId;
    if (Object.keys(topics).indexOf(activeTopicId) < Object.keys(topics).length - 1) {
      return topics[Object.keys(topics)[Object.keys(topics).indexOf(activeTopicId) + 1]].title;
    }
    return null;
  }
);

export const getNextExercise = createSelector(
  [getLessons, getSections, activeSectionId, activeExerciseId, getNextSection, getNextLesson],
  (lessons, sections, activeSection, activeExercise, nextSectionId, nextLessonId) => {
    if (
      activeSection &&
      activeExercise &&
      sections[activeSection].exercises.indexOf(activeExercise) < sections[activeSection].exercises.length - 1
    ) {
      return {
        nextExerciseId:
          sections[activeSection].exercises[sections[activeSection].exercises.indexOf(activeExercise) + 1],
        exerciseInNextSection: false,
      };
    }
    if (nextSectionId) {
      return {
        nextExerciseId: sections[nextSectionId].exercises.length > 0 ? sections[nextSectionId].exercises[0] : null,
        exerciseInNextSection: true,
      };
    }
    if (nextLessonId) {
      const firstSectionOfNextLessonId = lessons[nextLessonId].sections[0];
      return {
        nextExerciseId:
          sections[firstSectionOfNextLessonId].exercises.length > 0
            ? sections[firstSectionOfNextLessonId].exercises[0]
            : null,
        exerciseInNextSection: true,
      };
    }
    return { nextExerciseId: null, exerciseInNextSection: null };
  }
);
