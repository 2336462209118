import React, { PureComponent } from 'react';
import Svg, { Path } from './svg';

export default class ArrowRightIcon extends PureComponent {
  static defaultProps = { width: 24, height: 24, color: '#000' };

  render() {
    const { width, height, color } = this.props;
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <Path opacity=".87" fill="none" d="M24 24H0V0h24v24z" />
        <Path
          fill={color}
          d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
        />
      </Svg>
    );
  }
}
