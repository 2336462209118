import React from 'react';
import Divider from '@material-ui/core/Divider';

const UISeparator = props => (
  <Divider style={{ ...props.style, backgroundColor: props.light ? 'white' : 'black', opacity: 0.15 }}>
    {props.children}
  </Divider>
);

UISeparator.defaultProps = {
  style: {},
  children: null,
  light: false,
};

export default UISeparator;
