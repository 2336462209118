// @flow
import React from 'react';
import Svg, { Path } from './svg';

type Props = {
  width?: number,
  height?: number,
  color?: string,
};

export default class MovieIcon extends React.PureComponent<Props> {
  static defaultProps = {
    width: 24,
    height: 24,
    color: null,
  };

  render() {
    const { width, height, color } = this.props;
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <Path fill={color} d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" />
        <Path d="M0 0h24v24H0z" fill="none" />
      </Svg>
    );
  }
}
