import {
  firebaseAuthSubscriber,
  firebaseSaveUserKnowledgeTest,
  firebaseAuthSignOut,
  firebaseAuthSignInWithEmail,
  firebaseAuthSignInWithCustomToken,
  firebaseCreateUserWithEmail,
  firebaseListenForUserData,
  firebaseListenForUserSubscription,
  firebaseUpdateUserProgress,
  firebaseAuthResetEmail,
  firebaseEndUserSubscription,
  firebaseUpdateUserInfo,
  makeFirebaseUserActiveStatusPinger,
} from '~/utils/api';
import { getSubscriptionEntitiesAction, skipSubscriptionEntitiesAction } from '~/store/actions/entitiesActions';
import { persistor } from '~/store';

const userActiveStatusPinger = makeFirebaseUserActiveStatusPinger('userActiveStatus');

export const authSubscriberAction = () => (dispatch, getState) => {
  dispatch({ type: 'AUTH_SUBSCRIBE' });

  firebaseAuthSubscriber(async user => {
    if (user) {
      const userData =
        user._user && user._user.providerData // eslint-disable-line
          ? {
              uid: user._user.uid, // eslint-disable-line
              email:
                user._user.providerData[0] && user._user.providerData[0].email ? user._user.providerData[0].email : '', // eslint-disable-line
            }
          : {
              uid: user.uid,
              email: user.providerData[0] && user.providerData[0].email ? user.providerData[0].email : '',
            };

      dispatch({ type: 'AUTH_SIGNED_IN', payload: userData });
      dispatch(listenForUserDataAction(userData.uid));

      const {
        auth: {
          user: { userData: stateUserData },
        },
      } = getState();
      // Backup for web - If Name not updated to firebase on authCreateUserWithEmail.
      if (stateUserData.needsUploadToFirebase) {
        firebaseUpdateUserInfo(userData.uid, {
          firstName: stateUserData.firstName,
          lastName: stateUserData.lastName,
        });
      }

      await userActiveStatusPinger(user);
    } else {
      persistor.purge();
      dispatch({ type: 'AUTH_SIGNED_OUT' });
    }
  });
};

export const authCreateUserWithEmail = (email, pass, firstName, lastName) => (dispatch, getState) =>
  firebaseCreateUserWithEmail(email, pass, firstName, lastName)
    .then(result => {
      dispatch({
        type: 'AUTH_CREATE_USER_SUCCESS',
        payload: { firstName, lastName },
      });

      const {
        auth: {
          user: { uid },
        },
      } = getState();
      if (uid) {
        firebaseUpdateUserInfo(uid, { firstName, lastName });
      }
      return { status: true, response: result };
    })
    .catch(err => {
      dispatch({ type: 'AUTH_CREATE_USER_FAILED', payload: err });
      return { status: false, response: err };
    });

export const authSignInWithEmailAction = (email, pass) => dispatch =>
  firebaseAuthSignInWithEmail(email, pass)
    .then(result => {
      dispatch({ type: 'AUTH_SIGN_IN_SUCCESS' });
      return { status: true, response: result };
    })
    .catch(err => {
      dispatch({ type: 'AUTH_SIGN_IN_FAILED' });
      return { status: false, response: err };
    });

export const authSignInWithCustomToken = token => dispatch =>
  firebaseAuthSignInWithCustomToken(token)
    .then(result => {
      dispatch({ type: 'AUTH_SIGN_IN_SUCCESS' });
      return { status: true, response: result };
    })
    .catch(err => {
      dispatch({ type: 'AUTH_SIGN_IN_FAILED' });
      return { status: false, response: err };
    });

export const authResetPasswordAction = email => dispatch => {
  dispatch({ type: 'AUTH_RESET_PASSWORD_START' });
  return firebaseAuthResetEmail(email)
    .then(() => {
      dispatch({ type: 'AUTH_RESET_PASSWORD_SUCCESS' });
      return { success: true };
    })
    .catch(err => {
      dispatch({ type: 'AUTH_RESET_PASSWORD_ERROR' });
      return { success: false, code: err.code };
    });
};

export const authSignOutAction = () => dispatch => {
  firebaseAuthSignOut().then(() => {
    persistor.purge();
    dispatch({ type: 'AUTH_SIGNED_OUT' });
  });
};

export const listenForUserDataAction = uid => (dispatch, getState) => {
  firebaseListenForUserData(uid, userDate => {
    dispatch({ type: 'GET_USER_DATA', payload: userDate });
  });
  firebaseListenForUserSubscription(uid, subscriptionData => {
    const {
      entities: { fetchedSubscriptionContent },
    } = getState();
    const { validSubscription } = subscriptionData;
    if (!fetchedSubscriptionContent && validSubscription) dispatch(getSubscriptionEntitiesAction());
    else dispatch(skipSubscriptionEntitiesAction());
    dispatch({ type: 'GET_USER_SUBSCRIPTION_DATA', payload: subscriptionData });
  });
};

export const updateUserProgressAction = (uid, exerciseId, data) => (dispatch, getState) => {
  const { ui, auth } = getState();
  const alreadyCompleted =
    auth.user.progress && auth.user.progress[ui.activeExercise] ? auth.user.progress[ui.activeExercise] : false;
  if (
    alreadyCompleted &&
    alreadyCompleted.points &&
    alreadyCompleted.points.total > (data.points && data.points.total ? data.points.total : 0)
  ) {
    dispatch({ type: 'USER_PROGRESS_NO_UPDATE' });
  } else {
    firebaseUpdateUserProgress(uid, exerciseId, {
      ...data,
      topic: ui.activeTopic,
      section: ui.activeExercise,
      lesson: ui.activeLesson,
    }).then(() => {
      dispatch({ type: 'USER_PROGRESS_UPDATED' });
    });
  }
};

export const updateUserControlQuestionAction = (uid, exerciseId, data) => (dispatch, getState) => {
  const { ui, auth } = getState();
  const alreadyCompleted =
    auth.user.progress && auth.user.progress[ui.activeExercise] ? auth.user.progress[ui.activeExercise] : false;
  if (
    (!alreadyCompleted && data.completed) ||
    (data.completed &&
      (alreadyCompleted.points && alreadyCompleted.points.total ? alreadyCompleted.points.total : 0) <
        data.points.total)
  ) {
    firebaseUpdateUserProgress(uid, exerciseId, {
      ...data,
      topic: ui.activeTopic,
      section: ui.activeExercise,
      lesson: ui.activeLesson,
    }).then(() => {
      dispatch({ type: 'USER_CONTROL_QUESTION_UPDATED' });
    });
  } else {
    dispatch({ type: 'USER_CONTROL_QUESTION_NO_UPDATED' });
  }
};

export const saveUserKnowledgeTestAction = (uid, score) => dispatch => {
  dispatch({ type: 'SAVE_KNOWLEDGE_TEST_START' });
  firebaseSaveUserKnowledgeTest(uid, score)
    .then(response => {
      dispatch({ type: 'SAVE_KNOWLEDGE_TEST_SUCCESS', payload: response });
    })
    .catch(() => {
      dispatch({ type: 'SAVE_KNOWLEDGE_TEST_FAILED' });
    });
};

export const endSubscriptionAction = uid => dispatch => {
  dispatch({ type: 'END_SUBSCRIPTION_START' });
  return firebaseEndUserSubscription(uid)
    .then(response => {
      dispatch({ type: 'END_SUBSCRIPTION_SUCCESS', payload: response });
    })
    .catch(() => {
      dispatch({ type: 'END_SUBSCRIPTION_FAILED' });
    });
};
