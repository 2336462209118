import React from 'react';

import styles from './style';

const UIText = props => {
  switch (props.textType) {
    case 'h1':
      return (
        <h1
          style={{
            ...styles[props.textType],
            ...props.style,
            ...props.webStyle,
          }}
        >
          {props.children}
        </h1>
      );
    case 'h2':
      return (
        <h2
          style={{
            ...styles[props.textType],
            ...props.style,
            ...props.webStyle,
          }}
        >
          {props.children}
        </h2>
      );
    case 'h3':
      return (
        <h3
          style={{
            ...styles[props.textType],
            ...props.style,
            ...props.webStyle,
          }}
        >
          {props.children}
        </h3>
      );
    case 'h4':
      return (
        <h4
          style={{
            ...styles[props.textType],
            ...props.style,
            ...props.webStyle,
          }}
        >
          {props.children}
        </h4>
      );
    case 'p':
      return (
        <p
          style={{
            ...styles[props.textType],
            ...props.style,
            ...props.webStyle,
          }}
        >
          {props.children}
        </p>
      );
    case 'lead':
      return (
        <p
          style={{
            ...styles[props.textType],
            ...props.style,
            ...props.webStyle,
          }}
        >
          {props.children}
        </p>
      );
    case 'error':
      return (
        <p
          style={{
            ...styles[props.textType],
            ...props.style,
            ...props.webStyle,
          }}
        >
          {props.children}
        </p>
      );
    default:
      return <span style={{ ...styles[props.textType], ...props.style }}>{props.children}</span>;
  }
};

UIText.defaultProps = {
  children: null,
  textType: 'span',
  style: null,
};

export default UIText;
