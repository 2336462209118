// @flow
import React from 'react';
import { Container, Text } from '~/ui';

type Props = {
  completedExercises: number,
  totalExercises: number,
  color: string,
  colorDark: string,
  colorSecondary: string,
};

const TopicProgress = ({
  completedExercises,
  totalExercises,
  color = '#777',
  colorDark = '#000',
  colorSecondary = '#eee',
}: Props) => {
  const progress = Math.floor((completedExercises / totalExercises) * 100);

  return (
    <Container
      style={{
        justifyContent: 'flex-start',
        position: 'relative',
        paddingTop: 10,
        paddingBottom: 30,
      }}
    >
      <Container style={{ position: 'relative' }}>
        <Container
          style={{ position: 'absolute', width: '100%', height: 8, backgroundColor: colorSecondary, borderRadius: 4 }}
        />
        <Container
          style={{
            position: 'absolute',
            width: `${(completedExercises / totalExercises) * 100}%`,
            height: 8,
            borderRadius: 4,
            backgroundColor: colorDark,
          }}
        />
        <Container
          style={{
            ...styles.percentageText,
            borderColor: color,
            left: `${progress}%`,
          }}
          webstyle={{ transform: `translateX(${progress > 5 ? '-40%' : 0})` }}
          nativeStyle={{
            transform: [{ translateX: progress > 5 ? -12 : 0 }],
            width: 40,
          }}
        >
          <Text textType="small">{progress ? `${progress}%` : '0%'}</Text>
        </Container>
      </Container>
    </Container>
  );
};

const styles = {
  percentageText: {
    position: 'absolute',
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    left: '0',
    top: 12,
  },
};

export default TopicProgress;
