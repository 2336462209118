import React from 'react';
import { Text, Container, Touchable } from '~/ui';
import { InProgressCircleOutlineIcon, CheckCircleOutlineIcon } from '~/icons/';
import { VehicleTypes } from '../../utils/constants';

export default function SectionListItem({ topic, openSection, section, topicColor, vehicleType }) {
  return (
    <Touchable
      style={{
        height: 48,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }}
      action={openSection(topic.id, section.id)}
    >
      <Text style={{ alignSelf: 'center', color: '#000' }}>{section.title}</Text>
      <Container style={{ alignSelf: 'center', marginLeft: 'auto', display: 'flex', width: 30 }}>
        {section.completedExercises.length > 0 &&
          !section.completedControlQuesstionExercise &&
          section.nrExerciseTasks !== section.completedExercises.length && (
            <Container style={{ paddingRight: 0, paddingLeft: 4, display: 'flex' }}>
              <InProgressCircleOutlineIcon color={topicColor} width={22} height={22} />
            </Container>
          )}
        { 
          (section.completedControlQuesstionExercise || section.nrExerciseTasks === section.completedExercises.length) ? (
            <Container style={{ paddingRight: 1, paddingLeft: 1, display: 'flex' }}>
              <CheckCircleOutlineIcon width={27} height={27} color={topicColor} />
            </Container>
          ) : null
        }
      </Container>
    </Touchable>
  );
}
