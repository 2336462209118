// @flow
import React from 'react';
import { Container, Image, Text, ScrollView } from '~/ui';
import { getFirebaseImageUrl } from '~/utils/helpers';
import { LessonListWithId } from '~/components/LessonList';
import BackButton from '~/components/BackButton';
import WithTopicColor from '~/components/WithTopicColor';

type Props = {
  topic: {
    title: string,
    lessons: Array<string>,
    signatureImage: string,
    color: string,
  },
  openSection: () => void,
  style: {},
};

export default function TopicMenuContent({
  topic: { title, lessons, signatureImage, color },
  openSection,
  style,
}: Props) {
  return (
    <Container style={{ ...styles.root, ...style }}>
      <BackButton target="topic" style={{ position: 'absolute', top: 16, left: 16, zIndex: 100 }} />
      <ScrollView>
        <Container>
          <Image style={{ width: '100%', resizeMode: 'cover' }} src={getFirebaseImageUrl(signatureImage)} />
          <WithTopicColor>
            {topicColor => (
              <Text textType="h1" style={{ marginLeft: 8, textAlign: 'left', color: topicColor }}>
                {title}
              </Text>
            )}
          </WithTopicColor>
        </Container>
        <Container style={{ backgroundColor: 'white', marginTop: 24, paddingLeft: 10, paddingRight: 10 }}>
          {lessons &&
            lessons.map(lesson => (
              <LessonListWithId topicColor={color} openSection={openSection} {...{ key: lesson, lessonId: lesson }} />
            ))}
        </Container>
      </ScrollView>
    </Container>
  );
}

const styles = {
  root: {
    backgroundColor: 'white',
    height: '100vh',
    overflow: 'auto',
  },
};
