import React, { PureComponent } from 'react';
import Svg, { Path } from './svg';

export default class BackIcon extends PureComponent {
  static defaultProps = { width: 24, height: 24 };

  render() {
    const { width, height, color } = this.props;
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <Path d="M0 0h24v24H0z" fill="none" />
        <Path fill={color} d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
      </Svg>
    );
  }
}
