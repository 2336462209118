// @flow

export function fetchInstructions() {
  return {
    type: 'INSTRUCTIONS_FETCH',
    payload: {},
  };
}

export function loadedInstructions(instructions) {
  return {
    type: 'INSTRUCTIONS_LOADED',
    payload: {
      instructions,
    },
  };
}

export function markInstructionAsDoNotShow(exerciseTemplate: string) {
  return {
    type: 'INSTRUCTIONS_MARK_DO_NOT_SHOW',
    payload: {
      exerciseTemplate,
    },
  };
}
