import {
  UICardVideo,
  UICard,
  UICardHeader,
  UICardBody,
  UICardFooter,
  UICardImage,
  UICardTitle,
  UICustomCardHeader,
} from './Card';

export {
  UICard as Card,
  UICardBody as CardBody,
  UICardFooter as CardFooter,
  UICardHeader as CardHeader,
  UICardTitle as CardTitle,
  UICardImage as CardImage,
  UICustomCardHeader as CustomCardHeader,
  UICardVideo as CardVideo,
};
