// @flow
import React from 'react';
import Svg, { Path } from './svg';

type Props = {
  width?: number,
  height?: number,
  color?: string,
};

export default class SchoolIcon extends React.PureComponent<Props> {
  static defaultProps = {
    width: 24,
    height: 24,
    color: null,
  };

  render() {
    const { width, height, color } = this.props;
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <Path d="M0 0h24v24H0z" fill="none" />
        <Path fill={color} d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z" />
      </Svg>
    );
  }
}
