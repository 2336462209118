import { makeGetLessonById } from '~/store/selectors/lessonSelectors';
import { connect } from 'react-redux';
import LessonList from './LessonList';

export default connect(() => {
  const getLessonById = makeGetLessonById();
  return (state, ownProps) => ({
    lesson: getLessonById(state, ownProps),
  });
})(LessonList);
