import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Colors } from '~/ui';

const Modal2 = ({ children, visible, style, ...props }) => (
  <Modal
    open={visible}
    style={{ backgroundColor: Colors.lightGrey, overflowY: 'auto', WebkitOverflowScrolling: 'touch', ...style }}
    hideBackdrop
    disableAutoFocus
    {...props}
  >
    {children}
  </Modal>
);

export default Modal2;
