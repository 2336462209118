// @flow
import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    textAlign: 'left',
    textTransform: 'none',
  },
};

type Props = {
  classes: typeof styles,
  action?: () => void,
};

function Touchable({ classes, action, ...props }: Props) {
  return <Button className={classes.button} elevation={0} onClick={action} {...props} />;
}

Touchable.defaultProps = {
  action: null,
};

export default withStyles(styles)(Touchable);
