const initialState = {
  loading: true,
  loggedIn: false,
  user: {
    progress: {},
    instructions: {},
    knowledgeTests: [],
    approvedKnowledgeTests: 0,
    subscriptionStatus: 'UNSUBSCRIBED',
    validSubscription: false,
    validSubscriptionChecked: false,
    subscriptionData: {
      payments: [],
      recurringPaymentMethod: {},
      subscriptionValidTo: '',
      paymentStatus: '',
    },
    userData: {
      firstName: '',
      lastName: '',
      needsUploadToFirebase: false,
    },
  },
  error: false,
  loginError: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH_SUBSCRIBE':
      return {
        ...state,
        loading: true,
      };
    case 'AUTH_SIGNED_IN':
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        loggedIn: true,
        loading: false,
      };
    case 'AUTH_SIGNED_OUT':
      return {
        ...state,
        ...initialState,
        loading: false,
      };
    case 'AUTH_SIGN_IN_FAILED':
      return {
        ...state,
        loading: false,
        loginError: true,
      };
    case 'AUTH_CREATE_USER_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          userData: {
            ...state.user.userData,
            ...action.payload,
            needsUploadToFirebase: true,
          },
        },
        loading: false,
        createUserError: false,
        loginError: false,
      };
    case 'GET_USER_DATA':
      return {
        ...state,
        user: {
          ...state.user,
          userData: action.payload && action.payload.userData ? action.payload.userData : state.user.userData,
          knowledgeTests:
            action.payload && action.payload.knowledgeTest ? action.payload.knowledgeTest : state.user.knowledgeTests,
          approvedKnowledgeTests:
            action.payload && action.payload.knowledgeTest
              ? Object.keys(action.payload.knowledgeTest).filter(
                  testKey => action.payload.knowledgeTest[testKey].totalCorrect > 51,
                ).length
              : state.user.approvedKnowledgeTests,
          progress: action.payload && action.payload.progress ? action.payload.progress : state.user.progress,
          subscriptionStatus:
            action.payload && action.payload.subscription && action.payload.subscription.subscriptionStatus
              ? action.payload.subscription.subscriptionStatus
              : state.user.subscriptionStatus,
          instructions:
            action.payload && action.payload.instructions
              ? { ...state.user.instructions, ...action.payload.instructions }
              : { ...state.user.instructions },
        },
      };
    case 'GET_USER_SUBSCRIPTION_DATA':
      return {
        ...state,
        user: {
          ...state.user,
          validSubscriptionChecked: true,
          validSubscription:
            action.payload && action.payload.validSubscription !== undefined
              ? action.payload.validSubscription
              : state.user.validSubscription,
          subscriptionData:
            action.payload && action.payload.subscriptionData
              ? { 
                  ...state.user.subscriptionData, 
                  ...action.payload.subscriptionData, 
                  subscriptionValidTo: Math.max(action.payload.subscriptionData.subscriptionValidTo ?? 0, action.payload.subscriptionData.manualExpirationDate ?? 0) 
                }
              : { ...state.user.subscriptionData },
        },
        loading:
          action.payload && action.payload.loadedSubscriptionData
            ? !action.payload.loadedSubscriptionData
            : state.loading,
      };
    default:
      return state;
  }
};

export default authReducer;
