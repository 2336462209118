import colors from '../colors';

export default {
  h1: {
    fontSize: 26,
    marginBottom: 0,
    marginTop: 15,
    color: colors.darkGrey,
    fontFamily: 'Montserrat',
    fontWeight: '600',
  },
  h2: {
    fontSize: 20,
    margin: 0,
    color: colors.black,
    fontFamily: 'Montserrat',
    fontWeight: '600',
  },
  h3: {
    fontSize: 18,
    margin: 0,
    color: colors.black,
    fontFamily: 'Montserrat',
    fontWeight: '500',
  },
  h4: {
    fontSize: 16,
    margin: 0,
    color: colors.black,
    fontFamily: 'Montserrat',
    fontWeight: '500',
  },
  p: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 14,
    color: colors.textColor,
    lineHeight: '20px',
    fontFamily: 'Montserrat',
    fontWeight: '400',
  },
  lead: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16,
    lineHeight: '28px',
    color: colors.textColor,
    letterSpacing: '0.15px',
    fontFamily: 'Montserrat',
    fontWeight: '400',
  },
  span: {
    fontSize: 14,
    color: colors.textColor,
    fontFamily: 'Montserrat',
    fontWeight: '400',
  },
  link: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '400',
    color: colors.secondaryColor,
  },
  linkBold: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: colors.secondaryColor,
  },
  small: {
    fontSize: 12,
    color: colors.textColor,
    fontFamily: 'Montserrat',
    letterSpacing: '0.4px',
    fontWeight: '400',
  },
  bold: {
    fontSize: 14,
    color: colors.textColor,
    fontFamily: 'Montserrat',
    fontWeight: '600',
  },
  error: {
    fontSize: 16,
    color: colors.red,
    textAlign: 'center',
    margin: 5,
    marginTop: 10,
    fontFamily: 'Montserrat',
    fontWeight: '200',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 16,
    margin: 5,
    marginTop: 10,
    fontFamily: 'Montserrat',
    fontWeight: '200',
  },
};
