// @flow
import React from 'react';
import Svg, { Path } from './svg';

type Props = {
  width?: number,
  height?: number,
  color?: string,
};

export default class MovieIcon extends React.PureComponent<Props> {
  static defaultProps = {
    width: 24,
    height: 24,
    color: null,
  };

  render() {
    const { width, height, color } = this.props;
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <Path fill={color} d="M8 5v14l11-7z" />
        <Path d="M0 0h24v24H0z" fill="none" />
      </Svg>
    );
  }
}
