// @flow
import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
// eslint-disable-next-line no-unused-vars
import css from './UIInput.css';
import { Container } from '~/ui';
import { EyeIcon, EyeCloseIcon } from '~/icons';

type Props = {
  value: string | number,
  type: ?string,
  onChange: (text: string) => void,
  style: ?{ [key: any]: any },
  label: ?string,
  placeholder: ?string,
  disabled: ?string,
  variant: ?string,
  required: ?string,
  error: ?string,
  icon: any,
  iconPosition: 'start' | 'end',
  iconOnClick: ?() => void,
};

type State = {
  passwordMasked: boolean,
  style: { [key: any]: any },
  inputPropsStyle: { [key: any]: any },
};

export default class UIInput extends React.Component<Props, State> {
  state: State = {
    passwordMasked: true,
    style: {},
    inputPropsStyle: {},
  };

  static getDerivedStateFromProps(props: Props) {
    const style = { ...props.style };
    let backgroundColor = '';
    let borderRadius = '';
    if (props.style && props.style.backgroundColor) {
      const { backgroundColor: styleBg } = props.style;
      backgroundColor = styleBg;
      delete style.backgroundColor;
    }
    if (props.style && props.style.borderRadius) {
      const { borderRadius: styleBorderRadius } = props.style;
      borderRadius = styleBorderRadius;
      delete style.borderRadius;
    }
    return {
      style,
      inputPropsStyle: {
        ...(backgroundColor ? { backgroundColor } : {}),
        borderRadius,
      },
    };
  }

  get type() {
    const { type } = this.props;
    const { passwordMasked } = this.state;

    if (type === 'password' && !passwordMasked) {
      return 'text';
    }

    return this.props.type;
  }

  togglePassword = () => {
    this.setState(prevState => ({
      passwordMasked: !prevState.passwordMasked,
    }));
  };

  render() {
    const {
      value,
      type = 'text',
      onChange,
      label,
      placeholder,
      disabled,
      variant = 'filled',
      required,
      error,
      icon,
      iconPosition,
      iconOnClick,
    } = this.props;
    const { style, inputPropsStyle } = this.state;

    const { passwordMasked } = this.state;

    return (
      <Container style={{ width: '100%', position: 'relative' }}>
        <TextField
          hint={placeholder}
          label={label || false}
          value={value}
          type={this.type}
          onChange={(event: any) => onChange(event.target.value)}
          style={{
            ...style,
            width: '100%',
            border: 0,
          }}
          InputProps={{
            style: {
              border: 0,
              ...inputPropsStyle,
              ...(label ? {} : inputPadding),
            },
            endAdornment:
              icon || type === 'password' ? (
                <InputAdornment position={iconPosition}>
                  <IconButton onClick={iconOnClick || this.togglePassword}>
                    {type === 'password' && <>{passwordMasked ? <EyeIcon /> : <EyeCloseIcon />}</>}
                    {icon && icon}
                  </IconButton>
                </InputAdornment>
              ) : (
                false
              ),
          }}
          variant={label ? variant : 'standard'}
          className={variant === 'filled' ? 'inputTextField' : ''}
          disabled={disabled}
          required={required}
          error={error}
          placeholder={placeholder}
        />
      </Container>
    );
  }
}

const inputPadding = {
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
};
