import WebFont from 'webfontloader';

import '~/assets/fonts/Poppins_Regular.ttf';
import '~/assets/fonts/Poppins_Thin.ttf';
import '~/assets/fonts/Poppins_Bold.ttf';

export default () => {
  WebFont.load({
    custom: {
      families: ['Poppins-Regular'],
      urls: ['/fonts.css'],
    },
  });
};
