const initialState = {
  topics: {},
  lessons: {},
  exercises: {},
  sections: {},
  questions: {},
  loadingBaseContent: true,
  loadingSubscriptionContent: true,
  getSubscriptionContentError: false,
  fetchedSubscriptionContent: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_UI':
      return initialState;
    case 'GET_BASE_CONTENT':
      return {
        ...state,
        ...action.payload,
        loadingBaseContent: false,
      };
    case 'GET_SUBSCRIPTION_CONTENT':
      return {
        ...state,
        exercises: action.payload.exercises ? action.payload.exercises : state.exercises,
        questions: action.payload.questions || state.questions,
        loadingSubscriptionContent: false,
        fetchedSubscriptionContent: true,
      };
    case 'GET_SUBSCRIPTION_CONTENT_START':
      return {
        ...state,
        loadingSubscriptionContent: true,
      };
    case 'GET_SUBSCRIPTION_CONTENT_ERROR':
      return {
        ...state,
        loadingSubscriptionContent: false,
        getSubscriptionContentError: true,
      };
    case 'GET_SUBSCRIPTION_CONTENT_SKIP':
      return {
        ...state,
        loadingSubscriptionContent: false,
      };
    default:
      return state;
  }
};
