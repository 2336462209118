// @flow
import React, { Component } from 'react';
import { PlayerControls } from '~/ui';

type Props = {
  autoPlay: boolean,
  showControls: boolean,
  onEnded: ?() => void,
};

type State = {
  paused: boolean,
  mute: boolean,
  currentTime: number,
  ended: boolean,
};

class AudioPlayer extends Component<Props, State> {
  state = {
    paused: !this.props.autoPlay,
    mute: false,
    currentTime: 0,
    ended: false,
  };

  player: { current: any } = React.createRef();

  onEnded() {
    this.setState({
      paused: true,
      ended: true,
    });
    if (this.props.onEnded) this.props.onEnded();
  }

  onDragStop(currentTime: number) {
    if (this.player.current) this.player.current.currentTime = currentTime;
  }

  playPauseSound() {
    if (this.state.paused) {
      this.playSound();
    } else {
      this.pauseSound();
    }
  }

  playSound() {
    if (this.player.current) this.player.current.play();
    this.setState({
      paused: false,
      ended: false,
    });
  }

  pauseSound() {
    if (this.player.current) this.player.current.pause();
    this.setState({
      paused: true,
    });
  }

  toggleMuteSound() {
    this.setState(prevState => ({
      mute: !prevState.mute,
    }));
  }

  muteSound() {
    this.setState({
      mute: true,
    });
  }

  unMuteSound() {
    this.setState({
      mute: false,
    });
  }

  updateTime() {
    this.setState({
      currentTime: this.player.current.currentTime,
    });
  }

  render() {
    const { ended, currentTime, mute, paused } = this.state;
    const { showControls, autoPlay = true } = this.props;

    return (
      <div
        style={{
          background: 'transparent',
        }}
      >
        <audio
          ref={this.player}
          src={this.props.src}
          style={{ width: '100%', backgroundColor: 'transparent' }}
          muted={this.state.mute}
          autoPlay={autoPlay}
          onTimeUpdate={() => this.updateTime()}
          onEnded={() => this.onEnded()}
        >
          Your browser does not support audio.
        </audio>
        {showControls ? (
          <PlayerControls
            paused={paused}
            mute={mute}
            duration={this.player.current ? this.player.current.duration : 0}
            currentTime={currentTime}
            toggleMuteSound={() => this.muteSound()}
            togglePlaySound={() => this.playPauseSound()}
            dragStop={currentTime => this.onDragStop(currentTime)}
            ended={ended}
          />
        ) : null}
      </div>
    );
  }
}

export default AudioPlayer;
