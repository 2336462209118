export const colors = {
  primary: '#E53935',
  primaryDark: '#AB000D',
  primaryLight: '#FF6F60',
  primaryContrast: '#FDFDFD',
  secondary: '#02877A',
  secondaryDark: '#057c70',
  secondaryLight: '#98c6c2',
  secondaryContrast: '#FDFDFD',
  text: '#212121',
  menuText: '#f8e8e9',
  alternativText: '#03A9F4',
  success: '#4CAF50',
  warning: '#FFCA28',
  danger: '#F44336',
  screen: '#fff',
  blue: '#2196F3',
  darkBlue: '#7986CB',
  green: '#4CAF50',
  yellow: '#FFF176',
  white: '#FDFDFD',
  black: '#212121',
  red: '#E57373',
  darkGrey: '#757575',
  textGrey: '#212121',
  lightGrey: '#E0E0E0',
  gradientStart: '#A50008',
  gradientStop: '#ed0f1f',
  transparent: 'transparent',
};

export const fonts = {
  primary: '#112369',
};

export const MDAO_TOKEN_QUERY_KEY = 'mdao-token'
