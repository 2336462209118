import React from 'react';

export default props => <svg {...props} />;
export const Ellipse = props => <ellipse {...props} />;
export const G = props => <g {...props} />;
export const LinearGradient = props => <linearGradient {...props} />;
export const RadialGradient = props => <radialGradient {...props} />;
export const Line = props => <line {...props} />;
export const Path = props => <path {...props} />;
export const Polygon = props => <polygon {...props} />;
export const Polyline = props => <polyline {...props} />;
export const Rect = props => <rect {...props} />;
export const Symbol = props => <symbol {...props} />;
export const Text = props => <text {...props} />;
export const Use = props => <use {...props} />;
export const Defs = props => <defs {...props} />;
export const Stop = props => <stop {...props} />;
export const Tspan = props => <tspan {...props} />;
export const Circle = props => <circle {...props} />;
export const Mask = props => <mask {...props} />;
