const initialState = {
  state: 'IDLE',
  instructions: {},
};

export default function instructionsReducer(state = initialState, action) {
  switch (action.type) {
    case 'INSTRUCTIONS_FETCH':
      return {
        ...state,
        state: 'LOADING',
      };
    case 'INSTRUCTIONS_LOADED':
      return {
        ...state,
        state: 'IDLE',
        instructions: action.payload.instructions,
      };
    default:
      return state;
  }
}
